import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../store/store";
import { fetchClasses, getClassById } from "./classAPI";
import { AxiosResponse } from "axios";

export interface IClass {
    id?: number;
    className: string;
    shiftName: string;
    segmentName: string;
    isActive: true | false;
    shiftId: string;
    segmentId: string;
    name?: string;
}

export interface classState {
    data: IClass[];
    dataId: any;
    loading: boolean;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: classState = {
    data: null,
    dataId: null,
    loading: false,
    status: 'idle'
};


export const getClassesAsync = createAsyncThunk(
    "class/fetchClasses",
    async (params: {
        page?: number;
        shiftId?: number;
        segmentId?: number;
        rowsPerPage?: number;
        name?: string;
    }) => {
        const response = await fetchClasses(params) as AxiosResponse;

        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);
export const getClassesIdAsync = createAsyncThunk(
    "class/fetchClassesId",
    async (params: {
        id?: number;
    }) => {
        const response = await getClassById(params.id) as AxiosResponse;

        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const classSlice = createSlice({
    name: "class",
    initialState,

    reducers: {
        storeClasses: (state, action: PayloadAction<IClass[]>) => {
            state.data = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getClassesAsync.pending, (state) => {
                state.loading = true;
                state.status = 'loading'
            })
            .addCase(getClassesAsync.rejected, (state) => {
                state.loading = false;
                state.status = "failed";
            })
            .addCase(getClassesAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.status = "idle";
                state.data = action.payload.data;
            })
            .addCase(getClassesIdAsync.pending, (state) => {
                state.loading = true;
                state.status = 'loading'
            })
            .addCase(getClassesIdAsync.rejected, (state) => {
                state.loading = false;
                state.status = "failed";
            })
            .addCase(getClassesIdAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.status = "idle";
                state.data = action.payload.data;
            });
    },
});

export const { storeClasses } = classSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.class.value)`
export const selectClass = (state: RootState) => state.class;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd =
    (amount: number): AppThunk =>
    (dispatch, getState) => {
        //   const currentValue = selectDiscipline(getState());
        //   if (currentValue % 2 === 1) {
        //     dispatch(incrementByAmount(amount));
        //   }
    };

export default classSlice.reducer;
