import { Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.section``;

export const Wrapper = styled.section`
    background-color: #ffffff;
`;

export const DrawerContainer = styled.section`
    padding: 20px 15px;
    width: 400px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 1rem;
`;

export const DrawerTitle = styled(Typography)`
    font-size: 22px !important;
`;

export const LoadingWrapper = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
`;
