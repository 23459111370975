import Api from "../../services/api";
import { handleToast } from "../Toast";

export interface ICalendarSave {
    calendar: any;
    shift: string;
    shiftId: number;
    segment: string;
    segmentId: number;
    class: string;
    classId: number;
    startDate: string;
    endDate: string;
    month: string;
    id?: number;
}

interface IPostCalendarRequest {
    data: ICalendarSave[];
}

export function postCalendarSave(data: ICalendarSave) {
    return new Promise<IPostCalendarRequest>((resolve, reject) => {
        if (data.calendar) {
            Api.post("/calendar-save", { data: data })
                .then((response) => {
                    handleToast(false, "Calendario criado");
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    handleToast(true, error.message);
                });
        } else {
            handleToast(true, "Não foi possivel criar o calendario");
        }
    });
}
export function updateCalendarSave(data: ICalendarSave) {
    return new Promise<IPostCalendarRequest>((resolve, reject) => {
        if (data.calendar) {
            Api.put(`/calendar-save/${data.id}`, { data: data })
                .then((response) => {
                    handleToast(false, "Calendario criado");
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                    handleToast(true, error.message);
                });
        } else {
            handleToast(true, "Não foi possivel atualizar o calendario");
        }
    });
}

export function getCalendarSave(params: any) {
    return new Promise<IPostCalendarRequest>((resolve, reject) => {
        Api.get("/calendar-save", { params })
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
                handleToast(true, error.message);
            });
    });
}

export function getXlsx(data) {
    return new Promise<any>((resolve, reject) => {
        Api.post(`/xlsx`, data)
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
                handleToast(true, error.message);
            });
    });
}
