import Api from "../../services/api";

interface IShift {
    id?: number;
    name: string;
}

interface IPostShftRequest {
    data: IShift;
}

export function fetchShifts(params: object = null) {
    return new Promise((resolve, reject) => {
        Api.get("/shifts", { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function postShift(data: IShift) {
    return new Promise<IPostShftRequest>((resolve, reject) => {
        Api.post("/shifts", { data })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function updateShift(data: IShift) {
    return new Promise((resolve, reject) => {
        Api.put(`/shifts/${data.id}`, { data })
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function deleteShift(id: number) {
    return new Promise((resolve, reject) => {
        Api.delete(`/shifts/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
