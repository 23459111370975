import styled from "styled-components";
import { Form as FormikForm } from "formik";

export const Container = styled.div`
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;

export const ImageContainer = styled.div`
    display:flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    margin-top: 20px;
`

export const AlignContainer = styled.div`
    background: white;
    padding: 30px 20px;
    border-radius: 5px;
    margin: 0 auto;
    width: 400px;
    max-width: 400px;


    h3 {
        font-size: 20px;
        margin: 0 auto;
        width: fit-content;
    }
`;

export const Form = styled(FormikForm)`
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 1rem;

    margin-top: 20px;
`;
