// A mock function to mimic making an async request for data

import Api from "../../services/api";

interface IClass {
    id?: number;
    className: string;
    shiftName: string;
    segmentName: string;
    isActive: true | false;
    shiftId: string;
    segmentId: string;
}

interface IClasses {
    page?: number;
    shiftId?: number;
    segmentId?: number;
    name?: string;
}

interface IDeleteClass {
    id: number;
    isActive: boolean;
}

/**
 * 
 * @param params 
 * @returns Retorna listagem das turmas
 */
export async function fetchClasses(params: IClasses | null = null) {
    try {
        const response = await Api.get("/classes", { params });
        return response;
    } catch (e) {
        throw new Error(e);
    }
}

export function getClassById(id: number) {
    return new Promise((resolve, reject) => {
        Api.get(`/classes/${id}`)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function postClass(data: IClass) {
    return new Promise((resolve, reject) => {
        Api.post("/classes", { data })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function updateClass(data: IClass) {
    return new Promise((resolve, reject) => {
        Api.put(`/classes/${data.id}`, { data })
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function deleteClass(params: IDeleteClass) {
    return new Promise((resolve, reject) => {
        Api.delete(`/classes/${params.id}`, { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
