import { Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.section``;

export const Wrapper = styled.section`
    background-color: #ffffff;
`;

export const DrawerContainer = styled.section`
    padding: 20px 15px;
    width: 400px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    gap: 1rem;
`;

export const DrawerTitle = styled(Typography)`
    font-size: 22px !important;
    margin-bottom: 0 !important;
`;

export const DrawerSubtitle = styled(Typography)`
    font-size: 14px !important;
`;

export const LoadingWrapper = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AddClassContainer = styled.div`
    display: flex;
    flex-direction:row;
    gap: 10px;

    button {
        height: 40px;
        width: 100px;
        color: white;
        background: var(--primary-green);
        border-radius: 5px;
        border: none;
        outline: none;
        font-size: 20px;
        cursor: pointer;
    }
`;

export const ClassContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    p {
        background: #efefef;
        color: black;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        height: 40px;
        font-size: 16px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px;
        margin: 0;
    }

    button {
        height: 40px;
        width: 40px;
        color: white;
        background: var(--alert-primary);
        border-radius: 5px;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
    }
`;
