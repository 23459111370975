import React from "react";
import {
    Button,
    Divider,
    Drawer,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Table } from "../../components";
import { handleToast } from "../../components/Toast";
import {
    AddClassContainer,
    ClassContainer,
    Container,
    DrawerContainer,
    DrawerSubtitle,
    DrawerTitle,
    Form,
} from "./discipline.module";
import {
    createDisciplineClass,
    deleteDiscipline,
    deleteDisciplineClass,
    postDiscipline,
    updateDiscipline,
} from "./disciplineAPI";
import {
    getDisciplinesAsync,
    getDisciplinesClassesAsync,
} from "./disciplineSlice";
import { getClassesAsync } from "../class/classSlice";
import MaskedInput from "react-text-mask";

interface IDiscipline {
    id?: number;
    workload: number | null;
    isActive: boolean;
    discipline?: string;
    name?: string;
    classes?: Array<any>;
    shift?: {
        shiftId: number;
        shift: string;
    };
}

export function Discipline() {
    const dispatch = useAppDispatch();

    const { data, status, disciplineClass } = useAppSelector(
        (state) => state.disciplines
    );
    const [show, setShow] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<IDiscipline>({
        name: "",
        workload: null,
        isActive: true,
    });
    const [selectedClasses, setSelectedClasses] = React.useState([]);
    const [filteredClasses, setFilteredClasses] = React.useState([]);
    const [currentClass, setCurrentClass] = React.useState(null);
    const classSelector = useAppSelector((state) => state.class);

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

    React.useEffect(() => {
        const bootstrap = () => {
            dispatch(getDisciplinesAsync({ page, rowsPerPage }));
            dispatch(getClassesAsync({ page: -1 }));
        };

        return () => {
            bootstrap();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const handleChangeClass = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentClass(event.target.value);
        },
        []
    );

    React.useEffect(() => {
        setFilteredClasses(
            classSelector?.data?.filter(
                (item) =>
                    !selectedClasses?.find(
                        (n) => n?.classID === item?.id || n?.id === item?.id
                    )
            )
        );
    }, [classSelector?.data, selectedClasses]);

    const handleAddClass = async (classId) => {
        if (!classId) return;

        setCurrentClass("");

        await createDisciplineClass({
            disciplineId: values?.id,
            classId: classId?.classID || classId?.id,
        });

        handleToast(false, "Turma adicionada.");

        if (Array.isArray(selectedClasses)) {
            setSelectedClasses([...selectedClasses, classId]);
        } else {
            setSelectedClasses([classId]);
        }
    };

    const handleChange = React.useCallback(
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: event.target.value,
            });
        },
        [values]
    );

    const handleChangeSwitch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                isActive: event.target.checked,
            });
        },
        [values]
    );

    React.useEffect(() => {
        setSelectedClasses(values?.classes);
    }, [values?.classes, values]);

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (
            values?.name === null ||
            values?.name === undefined ||
            values?.name.length < 1
        )
            return handleToast(true, "Entre com um nome da disciplina.");

        if (
            values.workload === null ||
            values.workload === undefined ||
            values.workload < 0
        )
            return handleToast(true, "Entre com um carga horária válida.");

        if (values.id) {
            return updateDiscipline(values)
                .then(async () => {
                    const disciplineClassPromise = [];
                    dispatch(getDisciplinesAsync({ page }));
                    for (
                        let index = 0;
                        index < selectedClasses.length;
                        index++
                    ) {
                        disciplineClassPromise.push();
                    }

                    setValues({
                        id: null,
                        workload: null,
                        isActive: true,
                    });

                    handleToast(false, "Disciplina atualizada com sucesso.");

                    return setShow(false);
                })
                .catch((error) => {
                    setShow(false);
                    if (Array.isArray(error)) return handleToast(true, error);

                    return handleToast(true, error);
                });
        }

        return postDiscipline(values)
            .then((res: any) => {
                handleToast(false, "Disciplina cadastrada com sucesso.");
                setValues(res.data);

                // const disciplineClassPromise = [];

                // for (let index = 0; index < selectedClasses.length; index++) {
                //     disciplineClassPromise.push(
                //         createDisciplineClass({
                //             classId: res.data.data.id,
                //             disciplineId: selectedClasses[index],
                //         })
                //     );
                // }

                // Promise.all(disciplineClassPromise);

                // setShow(false);
                return dispatch(getDisciplinesAsync({ page, rowsPerPage }));
            })
            .catch((error) => {
                // setShow(false);
                handleToast(true, error.message);
                return;
            });
    };

    const handleDelete = React.useCallback(
        (id: number) => {
            deleteDiscipline({ id: id, isActive: false })
                .then(() => {
                    handleToast(false, "Disciplina deletada com sucesso.");
                    return dispatch(getDisciplinesAsync({ page }));
                })
                .catch((error) => {
                    handleToast(true, error.message);
                    return;
                });
        },
        [dispatch, page]
    );

    const handleSearch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                getDisciplinesAsync({
                    name: event.target.value,
                })
            );
        },
        [dispatch]
    );

    const handleDeleteClass = async (classId) => {
        const classIndex = selectedClasses.findIndex(
            (item) => item.disciplineClassId === classId
        );

        const tempArray = [...selectedClasses];

        tempArray.splice(classIndex, 1);

        setSelectedClasses(tempArray);

        if (values.id) {
            const obj = data?.find((item) => item?.id === values?.id);
            const classObj = obj?.classes?.find(
                (disciplineClass) =>
                    disciplineClass?.disciplineClassId === classId
            );

            if (classObj) {
                await deleteDisciplineClass(classObj?.disciplineClassId);
                handleToast(false, "Turma removida");
            }

            handleToast(false, "Turma removida");
        } else {
            handleToast(false, "Turma removida");
        }
    };

    const handleDisciplineClassList = React.useCallback(() => {
        if (!disciplineClass && values?.id)
            dispatch(getDisciplinesClassesAsync({ id: values?.id }));
    }, [disciplineClass, dispatch, values?.id]);

    const renderSelectedClasses = () => {
        if (!disciplineClass && disciplineClass !== null && values?.id) {
            handleDisciplineClassList();
            return disciplineClass?.map((item, index) => (
                <ClassContainer key={index}>
                    <p>{item?.name}</p>
                    <button
                        type="button"
                        onClick={() =>
                            handleDeleteClass(item?.disciplineClassId)
                        }
                    >
                        <Delete />
                    </button>
                </ClassContainer>
            ));
        } else {
            if (selectedClasses?.length) {
                return selectedClasses?.map((item, index) => (
                    <ClassContainer key={index}>
                        <p>{item?.name || item?.class}</p>
                        <button
                            type="button"
                            onClick={() =>
                                handleDeleteClass(item?.disciplineClassId)
                            }
                        >
                            <Delete />
                        </button>
                    </ClassContainer>
                ));
            }
        }
    };

    return (
        <Container className="daschboard-container">
            <Table
                columns={[
                    {
                        title: "Disciplina",
                        key: "name",
                        align: "left",
                    },
                    {
                        title: "Carga Horária",
                        key: "workload",
                        align: "left",
                    },
                    {
                        title: "Status",
                        key: "isActive",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar disciplina",
                        onClick: (rowData) => {
                            setValues(rowData);
                            return setShow(true);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar disciplina",
                        onClick: (rowData) => {
                            return handleDelete(rowData.id);
                        },
                    },
                ]}
                data={data}
                onClickAdd={() => setShow(true)}
                onClickRefresh={() => dispatch(getDisciplinesAsync({ page }))}
                handleChange={handleSearch}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                title="Disciplinas"
            />
            <Drawer anchor="right" open={show} onClose={() => setShow(false)}>
                <DrawerContainer>
                    <DrawerTitle>Disciplina</DrawerTitle>
                    <Divider
                        sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        variant="inset"
                    />
                    <Form onSubmit={handleSubmit}>
                        <TextField
                            label="Disciplina"
                            size="small"
                            value={values?.name}
                            onChange={handleChange("name")}
                            fullWidth
                        />
                        <MaskedInput
                            mask={[/[0-9]/, /\d/]}
                            guide={false}
                            defaultValue={values?.workload}
                            onChange={handleChange("workload")}
                            render={(ref, props) => (
                                <TextField
                                    inputRef={ref}
                                    label="Carga horaria"
                                    defaultValue={0}
                                    placeholder="60"
                                    size="small"
                                    name="workload"
                                    fullWidth
                                    autoComplete="off"
                                    {...props}
                                />
                            )}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={values?.isActive}
                                    onChange={handleChangeSwitch}
                                    inputProps={{ "aria-label": "isActive" }}
                                />
                            }
                            label="Ativo"
                        />
                        <Button fullWidth type="submit" variant="contained">
                            Enviar
                        </Button>
                    </Form>
                    <Form>
                        <DrawerTitle>Turmas</DrawerTitle>
                        <DrawerSubtitle>
                            Associar turmas à disciplina
                        </DrawerSubtitle>
                        <Divider
                            sx={{
                                marginTop: "5px",
                                marginBottom: "5px",
                            }}
                            variant="inset"
                        />

                        <AddClassContainer>
                            <TextField
                                label="Turmas"
                                size="small"
                                value={currentClass}
                                onChange={handleChangeClass}
                                fullWidth
                                select
                                disabled={!filteredClasses?.length}
                            >
                                {filteredClasses?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item}>
                                            {item?.className ||
                                                item?.name ||
                                                "Nenhum"}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                            <button
                                type="button"
                                onClick={() => handleAddClass(currentClass)}
                            >
                                salvar
                            </button>
                        </AddClassContainer>
                        {renderSelectedClasses()}
                    </Form>
                </DrawerContainer>
            </Drawer>
        </Container>
    );
}
