import styled from "styled-components";


export const Row = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    gap: 5px;
`;
