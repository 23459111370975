import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { tokenSelector } from "../../features/login/tokenSlice";

export function ProtectedRoute({ element, isLogin }: any) {
    const token = useAppSelector(tokenSelector);

    if (!token && !isLogin) return <Navigate to="/login" />;

    return element;
}

export default ProtectedRoute;
