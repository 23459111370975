import React from "react";
import { useReactToPrint } from "react-to-print";
import { handleToast } from "../components/Toast";

export function UsePrint() {
    const [componentToPrintPDF, setComponentToPrintPDF] =
        React.useState<React.MutableRefObject<HTMLDivElement | null>>(null);
    const [showButtons, setShowButtons] = React.useState<boolean>(true);
    const [title, setTitle] = React.useState<string>('');

    const handlePrintPDF = useReactToPrint({
        content: () => componentToPrintPDF.current,
        documentTitle: title,
        onPrintError: (error) => {
            handleToast(true, error);
            setShowButtons(true);
        },
        onBeforePrint: () => {
            setShowButtons(false);
        },
        onAfterPrint: () => {
            setShowButtons(true);
        },
    });

    return {
        setComponentToPrintPDF,
        handlePrintPDF,
        showButtons,
        setTitle
    };
}
