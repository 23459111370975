import styled from "styled-components";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import {
    IconButton,
    TableCell,
    TextField,
    Typography,
} from "@mui/material";

export const IcconButton = styled(IconButton)``;

export const TableWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const TableImageCell = styled(TableCell)`
    object-fit: fill;
    width: 10vh;

    img {
        max-width: 15vh;
    }
`;

export const CenteredWrapper = styled.section`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
`;

export const InputField = styled(TextField)`
    .MuiInputBase-root.MuiFilledInput-root {
        background-color: transparent !important;
    }
`;

export const Container = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;

    max-height: 600px;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    .pagination {
        height: auto;
        min-height: 52px;
        overflow:hidden;
    }
    `;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
    padding: 0 15px;
`;

export const ListContainer = styled.section`
    width: 100%;
    height: 400px;
    overflow-y: auto;
`;

export const LoadingContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    // background-color: rgba(0,0,0,0.2)
`;

export const ContentHeader = styled.section`
    padding: 10px;
    border-bottom: 0.75px solid #e0e0e0;
`;

export const ProductDelete = styled(AiFillDelete)`
    margin: 2px;
    cursor: pointer;
    color: red;
`;

export const ProductEdit = styled(FaEdit)`
    margin: 2px;
    cursor: pointer;
    color: green;
`;

export const Cell = styled(TableCell)`
    height: 56px;
`;

export const TableTitle = styled(Typography)`
    flex: 1;
    font-size: 22px !important;
`;

