import Api from "../../services/api";

interface ISegment {
    id?: number;
    name: string;
    isActive?: boolean;
}

interface IPostShftRequest {
    data: ISegment;
}

export function fetchSegments(params: object = null) {
    return new Promise((resolve, reject) => {
        Api.get("/segments", { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function postSegment(data: ISegment) {
    return new Promise<IPostShftRequest>((resolve, reject) => {
        Api.post("/segments", { data })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function updateSegment(data: ISegment) {
    return new Promise((resolve, reject) => {
        Api.put(`/segments/${data.id}`, { data })
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function deleteSegment(id: number) {
    return new Promise((resolve, reject) => {
        Api.delete(`/segments/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
