import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ICalendarSave, getCalendarSave, postCalendarSave } from "./indexApi";
import { RootState } from "../../store/store";

interface IResponseCalendarSave {
    calendar: any;
    disciplineHours: [];
    month: string;
    shift: string;
}

export interface ICalendarSaveRequest {
    data: IResponseCalendarSave[];
    status: "idle" | "loading" | "failed";
    error: any;
}

const initialState: ICalendarSaveRequest = {
    data: null,
    status: "idle",
    error: null,
};

export const postCalendarSaveAsync = createAsyncThunk(
    "calendarSave/postCalendarSave",
    async (calendar: ICalendarSave) => {
        const response = (await postCalendarSave(calendar)) as AxiosResponse;
        return response.data;
    }
);

export const updateCalendarSaveAsync = createAsyncThunk(
    "calendarSave/postCalendarSave",
    async (calendar: ICalendarSave) => {
        const response = (await postCalendarSave(calendar)) as AxiosResponse;
        return response.data;
    }
);

export const getCalendarSaveAsync = createAsyncThunk(
    "calendarSave/getCalendarSave",
    async (params: any) => {
        const response = (await getCalendarSave(params)) as AxiosResponse;

        return response.data;
    }
);

export const calendarSaveSlice = createSlice({
    name: "calendarSave",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        storeDisciplines: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getCalendarSaveAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getCalendarSaveAsync.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "failed";
            })
            .addCase(getCalendarSaveAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.data = action.payload.data;
            });
    },
});

export const { storeDisciplines } = calendarSaveSlice.actions;

export const selectCalendarSave = (state: RootState) => state.calendarSave;

export default calendarSaveSlice.reducer;
