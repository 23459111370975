import Api from "../../services/api";

interface ICalendar {
    id?: number;
    type: string;
    description?: string;
    name: string;
    date: Date;
}

// inteface ICalendar {
//     class: {
//         id: number
//         segment: string
//         segmentId: number
//         shift: string
//         shiftId: number
//     }
//     calendar: {
//         month: string
//         dateStart: string
//         dateEnd: string
//         workload: number
//         data: {
//             dayOfWeek: 'segunda' | 'terça' | 'quarta' | 'quinta' | 'sexta' | 'sábado' | 'domingo'
//             periods: {
//                 start: string
//                 teacher: string
//             }[]
//         } []
//     }[]
// }

// interface ICalendarSave {
//     calendar: string;
// }

// interface ICalendarSaveRequest {
//     data: ICalendarSave;
// }

interface IPostPeriodRequest {
    data: ICalendar;
}

export function fetchCalendar(params: object = null) {
    return new Promise((resolve, reject) => {
        Api.get("/calendar", { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function postCalendar(data: ICalendar) {
    return new Promise<IPostPeriodRequest>((resolve, reject) => {
        Api.post("/calendar", { data })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}


export function updateCalendar(data: ICalendar) {
    return new Promise((resolve, reject) => {
        Api.put(`/calendar/${data.id}`, { data })
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function deleteCalendar(id: number) {
    return new Promise((resolve, reject) => {
        Api.delete(`/calendar/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
