import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
    getTeacherList,
} from "./indexApi";
import { RootState } from "../../store/store";

export interface teacherList {
    data: any;
    status: "idle" | "loading" | "failed";
    error: string;
    loading: boolean,
}

const initialState: teacherList = {
    data: null,
    status: "idle",
    error: null,
    loading: false,
};

export interface IError {
    status: boolean;
    message: string;
}

export interface IGetTeacherList {
    classId: number;
    params: IParams;
}

export interface IParams {
    name: string;
}

export const getTeacherListAsync = createAsyncThunk(
    "teacher/getTeacherListAsync",
    async ({classId, params}:IGetTeacherList ) => {
        const  response  = (await getTeacherList(classId, params)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data
    }
);

export const teacherListSlice = createSlice({
    name: "teacherList",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        storeDisciplines: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getTeacherListAsync.pending, (state, action) => {
                state.loading = true;
                state.status = "loading";
            })
            .addCase(getTeacherListAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.status = "idle";
                state.data = action.payload.data;
            })
            .addCase(getTeacherListAsync.rejected, (state, action) => {
                state.loading = false;
                state.status = "failed";
            });
    },
});

export const { storeDisciplines } = teacherListSlice.actions;

export const selectTeacherList = (state: RootState) => state.teacherList;

export default teacherListSlice.reducer;