import React, { useCallback } from "react";
import MaskedInput from "react-text-mask";
import {
    Button,
    Divider,
    Drawer,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
} from "@mui/material";
import validator from "validator";
import { Edit, Delete } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Container, Form } from "./period.module";
import {
    getPeriodsAsync,
    selectPeriod,
    postPeriodsAsync,
    deletePeriodsAsync,
    updatePeriodsAsync,
} from "./periodSlice";
import { DrawerContainer, DrawerTitle } from "../teacher/teacher.module";
import { Table } from "../../components";
import { getShiftsAsync, selectShift } from "../shift/shiftSlice";

interface IPeriod {
    id?: number;
    start: string;
    end: string;
    shiftId?: number;
    isActive: boolean;
}

export function Periods() {
    const dispatch = useAppDispatch();
    const { data, status } = useAppSelector(selectPeriod);
    const shiftData = useAppSelector(selectShift);

    const [show, setShow] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<IPeriod>({
        start: "",
        end: "",
        shiftId: null,
        isActive: true,
    });
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [errors, setErrors] = React.useState({
        start: "",
        end: "",
        shiftId: "",
    });

    React.useEffect(() => {
        Promise.allSettled([
            dispatch(getPeriodsAsync({ page, rowsPerPage })),
            dispatch(getShiftsAsync({ page, rowsPerPage })),
        ]);
    }, [dispatch, page, rowsPerPage]);

    React.useEffect(() => {
        dispatch(getPeriodsAsync({ page, rowsPerPage }));
    }, [dispatch, page, rowsPerPage]);

    const handleUpdate = useCallback(() => {
        dispatch(updatePeriodsAsync(values))
            .then(() => {
                dispatch(getPeriodsAsync({ page, rowsPerPage }));
            })
            .catch((error) => {});
    }, [dispatch, page, rowsPerPage, values]);

    const handleCreate = useCallback(() => {
        dispatch(postPeriodsAsync(values))
            .then(() => {
                dispatch(getPeriodsAsync({ page, rowsPerPage }));
            })
            .catch((error) => {});
    }, [dispatch, page, rowsPerPage, values]);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();
            if (
                validator.isEmpty(errors.end) &&
                validator.isEmpty(errors.start)
            ) {
                if (!!values.id) {
                    handleUpdate();
                } else {
                    handleCreate();
                }

                const data = {
                    start: "",
                    end: "",
                    isActive: true,
                    shiftId: undefined,
                };
                setValues(data);
                setShow(false);
            }
        },
        [errors.end, errors.start, values, handleUpdate, handleCreate]
    );

    const handleVerify = React.useCallback(
        (data: string, property: string) => {
            if (validator.isEmpty(data)) {
                return setErrors({
                    ...errors,
                    [property]: "Campo obrigatório",
                });
            }
        },
        [errors]
    );

    const handleChange =
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: (event.target as HTMLInputElement).value,
            });
        };

    const handleClearError = (property: string) => {
        setErrors({
            ...errors,
            [property]: "",
        });
    };

    const handleDelete = React.useCallback(
        (id: number) => {
            dispatch(deletePeriodsAsync(id));
        },
        [dispatch]
    );

    const handleSearch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value;
            dispatch(getPeriodsAsync({ start: value }));
        },
        [dispatch]
    );

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            isActive: event.target.checked,
        });
    };

    return (
        <Container className="period-container">
            <Table
                columns={[
                    {
                        title: "Início",
                        key: "start",
                        align: "left",
                    },
                    {
                        title: "Fim",
                        key: "end",
                        align: "left",
                    },
                    {
                        title: "Turno",
                        key: "name",
                        align: "left",
                    },
                    {
                        title: "Status",
                        key: "isActive",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar período",
                        onClick: (rowData) => {
                            // listAvailabilitiesByTeacherId(rowData.id);
                            setValues(rowData);
                            return setShow(true);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar período",
                        onClick: (rowData) => {
                            return handleDelete(rowData.id);
                        },
                    },
                ]}
                data={data}
                onClickAdd={() => setShow(true)}
                onClickRefresh={() =>
                    dispatch(getPeriodsAsync({ page: 0, rowsPerPage }))
                }
                handleChange={handleSearch}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            />
            <Drawer
                anchor="right"
                open={show}
                onClose={() => {
                    const data = {
                        start: "",
                        end: "",
                        isActive: true,
                        shiftId: undefined,
                    };
                    setValues(data);
                    setErrors(data);
                    setShow(false);
                }}
            >
                <DrawerContainer>
                    <DrawerTitle>Período</DrawerTitle>
                    <Divider
                        sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        variant="inset"
                    />
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <MaskedInput
                            mask={[/[0-2]/, /\d/, ":", /[0-5]/, /\d/]}
                            value={values.start}
                            guide={false}
                            onChange={handleChange("start")}
                            onFocus={() => handleClearError("start")}
                            onBlur={() => handleVerify(values.start, "start")}
                            render={(ref, props) => (
                                <TextField
                                    inputRef={ref}
                                    label="Início"
                                    placeholder="08:40"
                                    size="small"
                                    value={values.start}
                                    name="start"
                                    error={!!errors.start}
                                    helperText={errors.start}
                                    fullWidth
                                    autoComplete="off"
                                    {...props}
                                />
                            )}
                        />
                        <MaskedInput
                            mask={[/[0-2]/, /\d/, ":", /[0-5]/, /\d/]}
                            value={values?.end}
                            guide={false}
                            onChange={handleChange("end")}
                            onFocus={() => handleClearError("end")}
                            onBlur={() => handleVerify(values?.end, "end")}
                            render={(ref, props) => (
                                <TextField
                                    inputRef={ref}
                                    label="Fim"
                                    placeholder="08:40"
                                    size="small"
                                    value={values?.end}
                                    name="end"
                                    error={!!errors?.end}
                                    helperText={errors?.end}
                                    fullWidth
                                    autoComplete="off"
                                    {...props}
                                />
                            )}
                        />
                        <TextField
                            label="Turno"
                            size="small"
                            placeholder="08:40"
                            value={values.shiftId}
                            name="shiftId"
                            onChange={handleChange("shiftId")}
                            onFocus={() => handleClearError("shiftId")}
                            onBlur={() =>
                                handleVerify(
                                    values?.shiftId?.toString(),
                                    "shiftId"
                                )
                            }
                            error={!!errors.shiftId}
                            helperText={errors.shiftId}
                            fullWidth
                            autoComplete="off"
                            select
                        >
                            {shiftData.data.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={values.isActive}
                                    onChange={handleChangeSwitch}
                                    inputProps={{ "aria-label": "isActive" }}
                                />
                            }
                            label="Ativo"
                        />
                        <Button type="submit" variant="contained">
                            Salvar
                        </Button>
                    </Form>
                </DrawerContainer>
            </Drawer>
        </Container>
    );
}
