import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "../../store/store";
import {
    fetchCalendar,
    updateCalendar,
    postCalendar,
    deleteCalendar,
} from "./calendarApi";

interface ICalendar {
    id?: number;
    type: string;
    description?: string;
    name: string;
    date: Date;
}

interface ICalendarResponse {
    data: ICalendar[];
}

export interface ICalendarState {
    data: ICalendar[];
    status: "idle" | "loading" | "failed";
    error: string | null;
}

const initialState: ICalendarState = {
    data: [],
    status: "idle",
    error: null,
};

export const getCalendarAsync = createAsyncThunk(
    "calendar/fetchCalendar",
    async (params: object) => {
        const response = (await fetchCalendar(params)) as AxiosResponse;

        return response.data;
    }
);

export const updateCalendarAsync = createAsyncThunk(
    "calendar/updateCalendar",
    async (data: ICalendar) => {
        const response = (await updateCalendar(data)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const postCalendarAsync = createAsyncThunk(
    "calendar/postPeriod",
    async (data: ICalendar) => {
        const response = (await postCalendar(data)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const deleteCalendarAsync = createAsyncThunk(
    "calendar/deletePeriod",
    async (id: number) => {
        const response = (await deleteCalendar(id)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const calendarSlice = createSlice({
    name: "calendar",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getCalendarAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                getCalendarAsync.fulfilled,
                (state, action: PayloadAction<ICalendarResponse>) => {
                    state.status = "idle";
                    state.data = action.payload.data;
                }
            )
            .addCase(getCalendarAsync.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(deleteCalendarAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                deleteCalendarAsync.fulfilled,
                (state, action: PayloadAction<{ data: number }>) => {
                    let temp = state.data.filter(
                        (item) => item.id !== action.payload.data
                    );
                    state.data = temp;
                    state.status = "idle";
                }
            )
            .addCase(deleteCalendarAsync.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(postCalendarAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                postCalendarAsync.fulfilled,
                (state, action: PayloadAction<{ data: ICalendar }>) => {
                    state.status = "idle";
                }
            )
            .addCase(postCalendarAsync.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(updateCalendarAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCalendarAsync.fulfilled, (state) => {
                state.status = "loading";
            })
            .addCase(updateCalendarAsync.rejected, (state) => {
                state.status = "failed";
            });
    },
});

// eslint-disable-next-line no-empty-pattern
export const {} = calendarSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.settings.value)`
export const selectCalendar = (state: RootState) => state.calendars;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//     (dispatch, getState) => {
//         const currentValue = selectCount(getState());
//         if (currentValue % 2 === 1) {
//             dispatch(incrementByAmount(amount));
//         }
//     };

export default calendarSlice.reducer;
