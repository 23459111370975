import React from "react";
import {
    Button,
    Divider,
    Drawer,
    FormControlLabel,
    Switch,
    TextField,
} from "@mui/material";
import validator from "validator";
import { Edit, Delete } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Container, Form } from "./segment.module";
import {
    deleteSegmentsAsync,
    getSegmentsAsync,
    postSegmentsAsync,
    selectSegment,
    updateSegmentsAsync,
} from "./segmentSlice";
import { DrawerContainer, DrawerTitle } from "../teacher/teacher.module";
import { Table } from "../../components";

interface ISegment {
    id?: number;
    name: string;
    isActive: boolean;
}

export function Segments() {
    const dispatch = useAppDispatch();
    const { data, status } = useAppSelector(selectSegment);

    const [show, setShow] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<ISegment>({
        name: "",
        isActive: true,
    });
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [errors, setErrors] = React.useState({
        name: "",
    });
    const [search, setSearch] = React.useState("");

    React.useEffect(() => {
        dispatch(getSegmentsAsync({ page, rowsPerPage }));
    }, [dispatch, page, rowsPerPage]);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();

            if (validator.isEmpty(errors.name)) {
                if (!!values.id) {
                    dispatch(updateSegmentsAsync(values))
                        .then(() =>
                            dispatch(getSegmentsAsync({ page, rowsPerPage }))
                        )
                        .catch((error) => {});
                } else {
                    dispatch(postSegmentsAsync(values))
                        .then(() =>
                            dispatch(getSegmentsAsync({ page, rowsPerPage }))
                        )
                        .catch((error) => {});
                }
                const data = { name: "", isActive: true };
                setValues(data);
                setShow(false);
            }
        },
        [dispatch, errors.name, rowsPerPage, values, page]
    );

    const handleVerify = React.useCallback(
        (data: string, property: string) => {
            if (validator.isEmpty(data)) {
                return setErrors({
                    ...errors,
                    [property]: "Campo obrigatório",
                });
            }
        },
        [errors]
    );

    const handleChange =
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: (event.target as HTMLInputElement).value,
            });
        };

    const handleClearError = (property: string) => {
        setErrors({
            ...errors,
            [property]: "",
        });
    };

    const handleDelete = React.useCallback(
        (id: number) => {
            dispatch(deleteSegmentsAsync(id));
        },
        [dispatch]
    );

    const handleSearch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value;
            dispatch(getSegmentsAsync({ name: value }));
            setSearch(value);
        },
        [dispatch]
    );

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            isActive: event.target.checked,
        });
    };

    return (
        <Container className="segment-container">
            <Table
                columns={[
                    {
                        title: "Módulo/Segmento",
                        key: "name",
                        align: "left",
                    },
                    {
                        title: "Status",
                        key: "isActive",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar módulo/segmento",
                        onClick: (rowData) => {
                            // listAvailabilitiesByTeacherId(rowData.id);
                            setValues(rowData);
                            return setShow(true);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar módulo/segmento",
                        onClick: (rowData) => {
                            return handleDelete(rowData.id);
                        },
                    },
                ]}
                data={data.filter((item) =>
                    item.name.toLowerCase().includes(search.toLowerCase())
                )}
                onClickAdd={() => setShow(true)}
                onClickRefresh={() =>
                    dispatch(getSegmentsAsync({ page: 0, rowsPerPage }))
                }
                handleChange={handleSearch}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            />
            <Drawer
                anchor="right"
                open={show}
                onClose={() => {
                    const data = { name: "", isActive: true };
                    setValues(data);
                    setErrors(data);
                    setShow(false);
                }}
            >
                <DrawerContainer>
                    <DrawerTitle>Período</DrawerTitle>
                    <Divider
                        sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        variant="inset"
                    />
                    <Form autoComplete="off">
                        <TextField
                            label="Módulo / Segmento"
                            size="small"
                            value={values.name}
                            name="name"
                            onChange={handleChange("name")}
                            onFocus={() => handleClearError("name")}
                            onBlur={() => handleVerify(values.name, "name")}
                            error={!!errors.name}
                            helperText={errors.name}
                            fullWidth
                            autoComplete="off"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={values.isActive}
                                    onChange={handleChangeSwitch}
                                    inputProps={{ "aria-label": "isActive" }}
                                />
                            }
                            label="Ativo"
                        />
                        <Button variant="contained" onClick={handleSubmit}>
                            Salvar
                        </Button>
                    </Form>
                </DrawerContainer>
            </Drawer>
        </Container>
    );
}
