import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;

    p {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        width: 120px;
        text-align: center;
    }

    button {
        background: var(--primary-green);
        border: none;
        outline: none;
        padding: 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        border-radius: 3px;

        color: white;

        :first-child {
            transform: rotate(180deg);
        }

        :disabled {
            cursor: initial;
            opacity: 0.6;
        }
    }
`;
