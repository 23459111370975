// A mock function to mimic making an async request for data

import Api from "../../services/api";

interface IDiscipline {
    id?: number;
    workload?: number | null;
    isActive?: boolean;
    discipline?: string;
    classes?: Array<any>;
    shift?: {
        shiftId: number;
        shift: string;
    };
}

interface IDisciplineClass {
    classId: number;
    disciplineId: number | string;
}

interface IDeleteDiscipline {
    id: number;
    isActive: boolean;
}

export function fetchDisciplines<T>(params: object = null) {
    return new Promise((resolve, reject) => {
        Api.get<T>("/disciplines", { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function fetchDisciplinesClasses<T>(params: object = null) {
    return new Promise((resolve, reject) => {
        Api.get<T>("/disciplineClass", { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function getDisciplineById(params) {
    return new Promise((resolve, reject) => {
        Api.get(`/disciplines/${params.id}`)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function postDiscipline(data: IDiscipline) {
    return new Promise((resolve, reject) => {
        Api.post("/disciplines", { data })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => reject(error));
    });
}

export function updateDiscipline(data: IDiscipline) {
    return new Promise((resolve, reject) => {
        Api.put(`/disciplines/${data.id}`, { data })
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function deleteDiscipline(params: IDeleteDiscipline) {
    return new Promise((resolve, reject) => {
        Api.delete(`/disciplines/${params.id}`, { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export async function createDisciplineClass(data: IDisciplineClass) {
    try {
        const response = await Api.post("/disciplineclass", { data });

        return response;
    } catch (e) {
        throw new Error(e);
    }
}

export async function deleteDisciplineClass(id: number) {
    try {
        const response = await Api.delete(`/disciplineclass/${id}`);

        return response;
    } catch (e) {
        throw new Error(e);
    }
}
export async function getDisciplineClass(params) {
    try {
        const response = await Api.get(`/disciplineClass/${params.id}?classes=1`);
        return response.data;
    } catch (e) {
        throw new Error(e);
    }
}
