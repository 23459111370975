import React from "react";
import { Button, Divider, Drawer, TextField } from "@mui/material";
import validator from "validator";
import { Edit, Delete } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Container, Form } from "./shift.module";
import {
    deleteShiftsAsync,
    getShiftsAsync,
    postShiftsAsync,
    selectShift,
    updateShiftsAsync,
} from "./shiftSlice";
import { DrawerContainer, DrawerTitle } from "../teacher/teacher.module";
import { Table } from "../../components";

interface IShift {
    id?: number;
    name: string;
}

export function Shifts() {
    const dispatch = useAppDispatch();
    const { data, status } = useAppSelector(selectShift);

    const [show, setShow] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<IShift>({
        name: "",
    });
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [errors, setErrors] = React.useState({
        name: "",
    });

    React.useEffect(() => {
        dispatch(getShiftsAsync({ page, rowsPerPage }));
    }, [dispatch, page, rowsPerPage]);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();

            if (validator.isEmpty(errors.name)) {
                if (!!values.id) {
                    dispatch(updateShiftsAsync(values))
                        .then(() =>
                            dispatch(getShiftsAsync({ page, rowsPerPage }))
                        )
                        .catch(() => {});
                } else {
                    dispatch(postShiftsAsync(values))
                        .then(() =>
                            dispatch(getShiftsAsync({ page, rowsPerPage }))
                        )
                        .catch(() => {});
                }
                const data = { name: "" };
                setValues(data);
            }
        },
        [dispatch, errors.name, rowsPerPage, values, page]
    );

    const handleVerify = React.useCallback(
        (data: string, property: string) => {
            if (validator.isEmpty(data)) {
                return setErrors({
                    ...errors,
                    [property]: "Campo obrigatório",
                });
            }
        },
        [errors]
    );

    const handleChange =
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: (event.target as HTMLInputElement).value,
            });
        };

    const handleClearError = (property: string) => {
        setErrors({
            ...errors,
            [property]: "",
        });
    };

    const handleDelete = React.useCallback(
        async (id: number) => {
            await dispatch(deleteShiftsAsync(id));
            await dispatch(getShiftsAsync({ page, rowsPerPage }));
        },
        [dispatch, page, rowsPerPage]
    );

    const handleSearch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value;
            dispatch(getShiftsAsync({ name: value }));
        },
        [dispatch]
    );

    return (
        <Container className="shift-container">
            <Table
                columns={[
                    {
                        title: "Turno",
                        key: "name",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar turno",
                        onClick: (rowData) => {
                            // listAvailabilitiesByTeacherId(rowData.id);
                            setValues(rowData);
                            return setShow(true);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar turno",
                        onClick: (rowData) => {
                            return handleDelete(rowData.id);
                        },
                    },
                ]}
                data={data}
                onClickAdd={() => setShow(true)}
                onClickRefresh={() =>
                    dispatch(getShiftsAsync({ page: 0, rowsPerPage }))
                }
                // handleEdit={(data) => {
                //     setValues(data);
                //     return setShow(true);
                // }}
                // handleDelete={handleDelete}
                handleChange={handleSearch}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            />
            <Drawer
                anchor="right"
                open={show}
                onClose={() => {
                    const data = { name: "" };
                    setValues(data);
                    setErrors(data);
                    setShow(false);
                }}
            >
                <DrawerContainer>
                    <DrawerTitle>Turno</DrawerTitle>
                    <Divider
                        sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        variant="inset"
                    />
                    <Form autoComplete="off">
                        <TextField
                            label="Turno"
                            placeholder="Manhã"
                            size="small"
                            value={values.name}
                            style={{ textAlign: "start" }}
                            name="name"
                            onChange={handleChange("name")}
                            onFocus={() => handleClearError("name")}
                            onBlur={() => handleVerify(values.name, "name")}
                            error={!!errors.name}
                            helperText={errors.name}
                            fullWidth
                            autoComplete="off"
                        />
                        <Button variant="contained" onClick={handleSubmit}>
                            Salvar
                        </Button>
                    </Form>
                </DrawerContainer>
            </Drawer>
        </Container>
    );
}
