import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../store/store";
import { fetchDisciplines, getDisciplineClass } from "./disciplineAPI";
import { AxiosResponse } from "axios";

interface IDiscipline {
    id: number;
    workload: number | null;
    isActive: boolean;
    discipline: string;
    classes: Array<any>;
    shift: {
        shiftId: number;
        shift: string;
    };
}

export interface IDisciplineClass {
    classId: number;
    name: string;
    disciplineClassId: number;
}
export interface disciplineState {
    data: IDiscipline[];
    disciplineClass: IDisciplineClass[];
    status: "idle" | "loading" | "failed";
}

const initialState: disciplineState = {
    data: null,
    disciplineClass: null,
    status: "idle",
};


export const getDisciplinesAsync = createAsyncThunk(
    "discipline/fetchDisciplines",
    async (params: object = {}) => {
        const response = (await fetchDisciplines(params)) as AxiosResponse;

        return response.data;
    }
);

export const getDisciplinesClassesAsync = createAsyncThunk(
    "discipline/fetchDisciplinesClasses",
    async (params: object = {}) => {
        const response = (await getDisciplineClass(params)) as AxiosResponse;

        return response.data;
    }
);

export const disciplineSlice = createSlice({
    name: "discipline",
    initialState,

    reducers: {
        storeDisciplines: (state, action: PayloadAction<IDiscipline[]>) => {
            state.data = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getDisciplinesAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getDisciplinesAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.data = action.payload.data;
            })
            .addCase(getDisciplinesClassesAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getDisciplinesClassesAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.disciplineClass = action.payload.data;
            })

    },
});

export const { storeDisciplines } = disciplineSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.discipline.value)`
export const selectDiscipline = (state: RootState) => state.disciplines;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd =
    (amount: number): AppThunk =>
        (dispatch, getState) => {
            //   const currentValue = selectDiscipline(getState());
            //   if (currentValue % 2 === 1) {
            //     dispatch(incrementByAmount(amount));
            //   }
        };

export default disciplineSlice.reducer;
