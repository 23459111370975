import {
    Avatar,
    Box,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import { useDrag } from "react-dnd";
import { ITeacher, ITeacherList } from "./interface";
import React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export function Teacher(props: ITeacher) {
    const { discipline, name, teacherAvailabilities } = props;

    const [{ isDragging }, drag] = useDrag({
        type: "teacher",
        collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
        item: props,
    });

    const [open, setOpen] = React.useState(false);

    const teacherList = React.useCallback(
        (color: string) => {
            return (
                <Box
                    sx={{
                        bgcolor: open ? "#fff" : null,
                        pb: open ? 2 : 0,
                        width: "100%",
                    }}
                >
                    <ListItemButton
                        alignItems="flex-start"
                        onClick={() => setOpen(!open)}
                        sx={{
                            px: 3,
                            pt: 2.5,
                            pb: open ? 0 : 2.5,
                            "&:hover, &:focus": {
                                "& svg": { opacity: open ? 1 : 1 },
                            },
                            border: open ? null : `4px solid ${color}`,
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            width: "100%",
                        }}
                    >
                        <ListItemAvatar sx={{ margin: 0 }}>
                            <Avatar>{name.slice(0, 1)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={name}
                            primaryTypographyProps={{
                                fontSize: 15,
                                fontWeight: "medium",
                                lineHeight: "20px",
                                mb: "2px",
                            }}
                            secondary={discipline}
                            secondaryTypographyProps={{
                                noWrap: true,
                                fontSize: 12,
                                lineHeight: "16px",
                                color: "#111",
                                width: "100%",
                            }}
                            sx={{ my: 0, width: "120px" }}
                        />
                        <KeyboardArrowDown
                            sx={{
                                mr: -1,
                                opacity: 0,
                                transform: open
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                transition: "0.2s",
                            }}
                        />
                    </ListItemButton>

                    {open &&
                        teacherAvailabilities.map((t: ITeacherList) => {
                            return (
                                <ListItemButton
                                    sx={{
                                        py: 0,
                                        minHeight: 32,
                                        color: "rgba(255,255,255,.8)",
                                        marginLeft: "10px",
                                        width: "110%",
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            t?.dayOfWeek
                                                ? t?.dayOfWeek
                                                : "Cadastre disponibilidades para o(a) professor(a)"
                                        }
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            fontWeight: "small",
                                            color: "#111",
                                        }}
                                        secondary={
                                            t?.start
                                                ? `${t?.start} - ${t?.end}`
                                                : null
                                        }
                                    />
                                    <ListItemAvatar
                                        sx={{
                                            margin: 0,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FiberManualRecordIcon
                                            sx={{
                                                color: "#FF0000",
                                                width: "15px",
                                            }}
                                        />
                                    </ListItemAvatar>
                                </ListItemButton>
                            );
                        })}
                </Box>
            );
        },
        [discipline, name, open, teacherAvailabilities]
    );

    const color = React.useCallback(() => {
        if (teacherAvailabilities.length === 1) {
            return teacherList("#1ea418");
        }
        if (teacherAvailabilities.length === 2) {
            return teacherList("#ff8400");
        }
        if (teacherAvailabilities.length >= 3) {
            return teacherList("#FF0000");
        }
    }, [teacherAvailabilities, teacherList]);

    React.useEffect(() => {
        color();
    }, [color, name, teacherAvailabilities]);

    return (
        <span
            ref={drag}
            style={{ opacity: isDragging ? "0.5" : "1", width: "100%" }}
            className="teacher-list"
        >
            {color()}
        </span>
    );
}
