import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Container, Wrapper } from "./settings.module";
import { Periods } from "../period/Period";
import { Calendar } from "../calendar/Calendar";
import { Shifts } from "../shift/Shift";
import { Segments } from "../segment/Segment";

export function Settings() {
    const [tab, setTab] = React.useState<string>("shift");
    
    const handleChangeTab = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
     }, []);

    return (
        <Container className="settings-container">
            <Wrapper
                sx={{
                    typography: "body1",
                }}
            >
                <TabContext value={tab}>
                    <Wrapper
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                        }}
                    >
                        <TabList onChange={handleChangeTab}>
                            <Tab label="Turnos" value="shift" />
                            <Tab label="Períodos" value="period" />
                            <Tab label="Segmentos / Módulos" value="segments" />
                            <Tab label="Feriados" value="calendar" /> 
                        </TabList>
                    </Wrapper>
                    <TabPanel value="shift">
                        <Shifts />
                    </TabPanel>
                    <TabPanel value="period">
                        <Periods />
                    </TabPanel>
                    <TabPanel value="segments">
                        <Segments />
                    </TabPanel>
                    <TabPanel value="calendar">
                        <Calendar />
                    </TabPanel>
                </TabContext>
            </Wrapper>
        </Container>
    );
}
