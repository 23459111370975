import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { Container, List } from "./styles";
import { Teacher } from "./parts/Teacher";
import { TextField } from "@mui/material";
import { getTeacherListAsync } from "../../features/teacherList/teacherList.Slice";
import { LoadingWrapper } from "../../features/class/class.module";
import Loading from "react-loading";

interface ITeacherListProps {
    calendar: any;
    classId?: number;
}

interface ITeacherAvailabilities {
    dayOfWeek: string;
    end: string;
    start: string;
    id: number;
}
interface ITeacherList {
    classId: number;
    discipline: string;
    teacher: string;
    teacherId: number;
    teacherActive: boolean;
    teacherAvailabilities?: ITeacherAvailabilities[];
}

export function TeacherList(props: ITeacherListProps) {
    const dispatch = useAppDispatch();
    const teacherList = useAppSelector((state) => state.teacherList);

    const { classId } = props;

    const [filter, setFilter] = React.useState<string>("");

    useEffect(() => {
        dispatch(
            getTeacherListAsync({ classId: classId, params: { name: filter } })
        );
    }, [classId, dispatch, filter]);

    return (
        <Container>
            <h3>Professores</h3>
            <TextField
                id="standard-basic"
                label="Filtro"
                variant="standard"
                onChange={(e) => setFilter(e.target.value)}
                sx={{ width: "100%", margin: "10px 0" }}
            />
            <List>
                {teacherList?.status === "loading" && (
                    <LoadingWrapper
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Loading
                            type="spinningBubbles"
                            color={"rgb(9, 160, 17)"}
                            width={30}
                            height={30}
                        />
                    </LoadingWrapper>
                )}
                {teacherList?.status === "idle" &&
                    teacherList?.data?.map((teacher: ITeacherList) => {
                        if (teacher?.teacherId) {
                            return (
                                teacher?.teacherActive && (
                                    <Teacher
                                        key={teacher?.teacherId}
                                        {...teacher}
                                        name={teacher.teacher}
                                    />
                                )
                            );
                        } else return <div>Não foi possivel achar</div>;
                    })}
            </List>
        </Container>
    );
}

export default TeacherList;
