import React from "react";
import { useSelector } from "react-redux";
import { handleToast } from "../components/Toast";
import { useAppDispatch } from "../app/hooks";
import {
    addAvailability,
    clearAvailabilities,
    getAvailabilitiesByTeacherIdAsync,
    selectAvailability,
    subAvailability,
    updateAvailabilityAsync,
    deleteAvailabilityById,
    createAvailability,
} from "../features/availability/availabilitySlice";

interface IAvailability {
    id?: number;
    teacherId?: number;
    end: string;
    start: string;
    dayOfWeek: string;
}

export const UseAvailability = () => {
    const dispatch = useAppDispatch();
    const availability = useSelector(selectAvailability);

    const handleDeleteAvailabilityByIndex = React.useCallback(
        (index: number) => dispatch(subAvailability({ index })),
        [dispatch]
    );

    const handleDeleteAvailability = React.useCallback(
        ({ id, index }: any) => {
            dispatch(subAvailability({ index }));
            dispatch(deleteAvailabilityById(id));
        },
        [dispatch]
    );

    const handleAddAvailability = React.useCallback(
        () => dispatch(addAvailability()),
        [dispatch]
    );

    const handleUpdateAvailability = React.useCallback(
        async (data: IAvailability) => {
            try {
                await dispatch(updateAvailabilityAsync(data));
                await dispatch(
                    getAvailabilitiesByTeacherIdAsync(data.teacherId)
                );
                handleToast(false, "Disponibilidade atualizada com sucesso");
            } catch (error) {
                handleToast(true, error.message);
            }
        },
        [dispatch]
    );

    const listAvailabilitiesByTeacherId = React.useCallback(
        (teacherId: number) => {
            dispatch(getAvailabilitiesByTeacherIdAsync(teacherId));
        },
        [dispatch]
    );

    const resetAvailabilities = React.useCallback(() => {
        dispatch(clearAvailabilities());
    }, [dispatch]);

    const handleCreateAvailability = React.useCallback(
        async (data: IAvailability) => {
            try {
                await dispatch(createAvailability(data));
                handleToast(false, "Disponibilidade criada com sucesso");
            } catch (error) {
                handleToast(true, error.message);
            } finally {
                dispatch(getAvailabilitiesByTeacherIdAsync(data.teacherId));
            }
        },
        [dispatch]
    );

    return {
        availability,
        handleDeleteAvailability,
        handleAddAvailability,
        handleUpdateAvailability,
        listAvailabilitiesByTeacherId,
        resetAvailabilities,
        handleDeleteAvailabilityByIndex,
        handleCreateAvailability,
    };
};
