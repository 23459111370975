import { Typography } from '@mui/material';
import styled from 'styled-components';


export const Container=styled.section`
    
`;

export const Wrapper=styled.section`
    background-color: #FFFFFF;
`;

export const DrawerContainer = styled.section`
    padding: 20px 15px;
    width: 400px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
    gap: 1rem;
`;

export const Row = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    gap: 5px;
`;

export const DrawerTitle = styled(Typography)`
    font-size: 22px!important;
`;

export const Label = styled(Typography)`
    flex: 1;
    font-size: 18px!important;
`;

export const DrawerSubtitle = styled(Typography)`
    font-size: 18px!important;
    color: #cecece;
`;

export const LoadingWrapper = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
`;
