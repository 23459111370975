import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../services/api";

interface ITeacher {
    id?: number;
    name: string;
    discipline?: {
        discipline: string;
        disciplineId: number;
        workload: number;
    };
    TeacherAvailabilities: IAvailability[];
    isActive: true | false;
    workload: number;
}

interface IAvailability {
    id: number;
    dayOfWeek: string;
    start: string;
    end: string;
    TeacherAvailabilities?: IAvailability[];
}

interface IGetTeacherRequest {
    page?: number;
    rowsPerPage?: number;
    classId?: number;
    shiftId?: number;
    segmentId?: number;
}

interface IClassSchedule {
    id: number;
    discipline: {
        id: number;
        discipline: string;
        workload: number;
    };
    segment: {
        name: string;
        id: number;
    };
    shift: {
        name: string;
        id: number;
    };
    name: string;
    isActive: boolean;
    TeacherAvailabilities?: {
        id: number;
        dayOfWeek: string;
        start: string;
        end: string;
    }[];
}

export async function fetchTeachers<T>(params: object = null) {
    try {
        const response = await Api.get<T>("/teachers", { params });
        return response;
    } catch (e: any) {
        throw new Error(e.response.data);
    }
}

export const getTeachers = createAsyncThunk<
    IClassSchedule[],
    IGetTeacherRequest
>("teacher/fetchTeachers", async (params, thunkApi) => {
    try {
        const response = await Api.get<IClassSchedule[]>("/teachers", {
            params,
        });

        return response.data;
    } catch (e: any) {
        throw new Error(e.response.data);
    }
});

export function getTeacherById(id: number) {
    return new Promise((resolve, reject) => {
        Api.get(`/teachers/${id}`)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function postTeacher(data: ITeacher) {
    return new Promise((resolve, reject) => {
        Api.post("/teachers", { data })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function updateTeacher(data: ITeacher) {
    return new Promise((resolve, reject) => {
        Api.put(`/teachers/${data.id}`, { data })
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function deleteTeacher(id: number) {
    return new Promise((resolve, reject) => {
        Api.delete(`/teachers/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
