import styled from "styled-components";
import { Box } from "@mui/material";

export const Container = styled.section``;

export const Wrapper = styled(Box)`
    background-color: #ffffff;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
