import { Container } from "./styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface IProps {
    next: any;
    prev: any;
    title?: string;
    currPage: number;
    lastPage: number;
    firstPage: number;
}

const Pagination: React.FC<IProps> = ({
    next,
    prev,
    title,
    currPage,
    lastPage,
    firstPage,
}) => {
    return (
        <Container>
            <button onClick={prev} disabled={currPage === firstPage}>
                <NavigateNextIcon />
            </button>

            <p>{title || currPage}</p>

            <button onClick={next} disabled={currPage === lastPage}>
                <NavigateNextIcon />
            </button>
        </Container>
    );
};

export default Pagination;
