import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
} from "@reduxjs/toolkit";

import homeReducer from "../features/home/homeSlice";
import userReducer from "../features/login/userSlice";
import tokenReducer from "../features/login/tokenSlice";
import teacherReducer from "../features/teacher/teacherSlice";
import disciplineReducer from "../features/discipline/disciplineSlice";
import settingsReducer from "../features/settings/settingsSlice";
import availabilitiesReducer from "../features/availability/availabilitySlice";
import periodsReducer from "../features/period/periodSlice";
import calendarsReducer from "../features/calendar/calendarSlice";
import calendarSaveReducer from "../components/Calendar/indexSlice"
import shiftsReducer from "../features/shift/shiftSlice";
import segmentsReducer from "../features/segment/segmentSlice";
import classReducer from "../features/class/classSlice";
import teacherListReducer from '../features/teacherList/teacherList.Slice'

import storage from "redux-persist/lib/storage";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";

const reducers = combineReducers({
    disciplines: disciplineReducer,
    home: homeReducer,
    teacherList: teacherListReducer,
    teachers: teacherReducer,
    settings: settingsReducer,
    availabilities: availabilitiesReducer,
    periods: periodsReducer,
    calendars: calendarsReducer,
    calendarSave: calendarSaveReducer,
    shifts: shiftsReducer,
    segments: segmentsReducer,
    class: classReducer,
    token: tokenReducer,
    user: userReducer,
});

type PersistConfigType = {
    key: string;
    storage: any;
    whitelist: Array<string>;
};

const persistConfig: PersistConfigType = {
    key: "root",
    storage: storage,
    whitelist: ["token"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        });

        return middlewares;
    },
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export { persistor, store };
