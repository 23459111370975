import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearToken, tokenSelector } from "../../features/login/tokenSlice";
import { Container } from "./styles";

export function LogoutButton() {
    const dispatch = useAppDispatch();
    const token = useAppSelector(tokenSelector);

    if (!token) return null;

    return <Container onClick={() => dispatch(clearToken())}>Sair</Container>;
};

export default LogoutButton;
