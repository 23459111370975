import styled from "styled-components";

export const Container = styled.button`
    padding: 10px 20px;

    color: white;
    background: transparent;
    outline: none;
    margin-left: auto;
    cursor: pointer;
    border: none;
    font-weight: 700;
    font-size: 16px;
`;
