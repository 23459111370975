import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState, AppThunk } from "../../store/store";
import { fetchTeachers } from "./teacherAPI";

interface IDiscipline {
    disciplineId: number;
    discipline: string;
    workload: number;
}

interface IAvailability {
    id?: number;
    teacherId?: number;
    end: string;
    start: string;
    dayOfWeek: string;
}

interface ITeacher {
    id: number;
    name: string;
    discipline?: IDiscipline;
    availabilities: IAvailability[];
    isActive: boolean;
    TeacherAvailabilities?: IAvailability[];
}

export interface teacherState {
    data: ITeacher[];
    status: "idle" | "loading" | "failed";
}

// interface IGetTeacherParams {
//     page?: number;
//     rowsPerPage?: number;
//     classId?: number;
// }

const initialState: teacherState = {
    data: [],
    status: "idle",
};

export const getTeachersAsync = createAsyncThunk(
    "teacher/fetchTeachers",
    async (params: object) => {
        const response = (await fetchTeachers(params)) as AxiosResponse;

        return response.data;
    }
);

export const teacherSlice = createSlice({
    name: "teacher",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        storeTeachers: (state, action: PayloadAction<ITeacher[]>) => {
            state.data = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getTeachersAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTeachersAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.data = action.payload.data;
            });
    },
});

export const { storeTeachers } = teacherSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.teacher.value)`
export const selectTeacher = (state: RootState) => state.teachers;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd =
    (amount: number): AppThunk =>
    (dispatch, getState) => {
        //   const currentValue = selectCount(getState());
        //   if (currentValue % 2 === 1) {
        //     dispatch(incrementByAmount(amount));
        //   }
    };

export default teacherSlice.reducer;
