import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Delete } from "@mui/icons-material";
import { useDrag, useDrop } from "react-dnd";
import { List, ListItemText, Popover } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { storeHome } from "../../../features/home/homeSlice";
import { Container } from "../ConfirmationModal/styles";
import { RemoveButton } from "../styles";
import "react-confirm-alert/src/react-confirm-alert.css";

interface IProps {
    period: any;
    name?: string;
    discipline: string;
    teacher: any;
    periodStart: string;
    dayOfWeek: string;
    month: string;
    holiday: boolean;
    data: any;
}

interface ITeacherAvalabilities {
    dayOfWeek: string;
    end: string;
    start: string;
    id: number;
}

export function DraggableCell({
    period,
    teacher,
    discipline,
    periodStart,
    dayOfWeek,
    holiday,
    data,
    name,
}: IProps) {
    const dispatch = useAppDispatch();
    const homeSelect = useAppSelector((state) => state.home);
    const teacherSelect = useAppSelector((state) => state.teacherList);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [{ isDragging }, drag] = useDrag({
        type: "teacher",
        collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
        item: {
            teacherId: teacher.teacherId,
            disciplineId: teacher.disciplineId,
            teacher: teacher.teacher,
            discipline: teacher.discipline,
        },
    });

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const options = {
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: "overlay-custom-class-name",
    };

    const confirm = (title: string, message: string, onConfirm: any) =>
        confirmAlert({
            ...options,
            message,
            title,
            customUI: ({ onClose }) => {
                return (
                    <Container>
                        <h1>{title}</h1>
                        <p>{message}</p>
                        <div className="buttons">
                            <button
                                onClick={() => {
                                    onConfirm();
                                    onClose();
                                }}
                            >
                                Ok
                            </button>
                            <button onClick={onClose}>Cancelar</button>
                        </div>
                    </Container>
                );
            },
        });

    const checkCanDrop = (
        teacherId: number,
        periods: ITeacherAvalabilities[]
    ) => {
        if (!periods?.length) {
            const testTeacher = teacherSelect?.data?.find(
                (teacher) => teacher.teacherId === teacherId
            );

            if (!testTeacher) return false;

            return !!testTeacher.teacherAvailabilities.find(
                (e) => e.dayOfWeek === dayOfWeek && e.start === period.start
            );
        }

        if (holiday) return false;

        return !!periods.find(
            (e) => e.dayOfWeek === dayOfWeek && e.start === period.start
        );
    };

    const [, drop] = useDrop(
        () => ({
            accept: "teacher",
            collect: (monitor) => ({ isOver: !!monitor.isOver() }),
            drop: (item: {
                teacherId: number;
                teacherAvalabilities: any;
                teacher: string;
                discipline: string;
                disciplineId: number;
            }) => {
                if (checkCanDrop(item.teacherId, item?.teacherAvalabilities)) {
                    updateCalendar(
                        item?.teacherId,
                        item?.teacher,
                        item?.discipline,
                        item?.disciplineId
                    );
                } else {
                    confirm(
                        "Aviso",
                        "Horário ou dia não válido para o professor, deseja mover mesmo assim?",
                        () =>
                            updateCalendar(
                                item?.teacherId,
                                item?.teacher,
                                item.discipline,
                                item?.disciplineId
                            )
                    );
                }
            },
        }),
        [homeSelect, teacherSelect, holiday]
    );

    const updateCalendar = (
        teacherId: number,
        teacher: string,
        discipline: string,
        disciplineId: number
    ) => {
        const newCalendar = [...homeSelect?.data?.result[0]?.calendar];

        const currDay = data?.day - 1;

        const dayIndex = newCalendar[currDay].periods.findIndex(
            (item) => item.start === periodStart
        );

        if (dayIndex === -1) return;

        const copyPeriods = [...newCalendar[currDay].periods];

        const newObj = {
            ...copyPeriods[dayIndex],
            teacher,
            teacherId,
            discipline,
            disciplineId,
        };

        copyPeriods[dayIndex] = newObj;

        newCalendar[currDay] = {
            ...newCalendar[currDay],
            periods: copyPeriods,
        };

        dispatch(
            storeHome({
                ...homeSelect.data,
                result: [
                    { ...homeSelect.data.result[0], calendar: newCalendar },
                ],
            })
        );
    };

    const removeTeacher = () => {
        const newCalendar = [...homeSelect?.data?.result[0]?.calendar];

        const currDay = data?.day - 1;

        const dayIndex = newCalendar[currDay].periods.findIndex(
            (item) => item.start === periodStart
        );

        if (dayIndex === -1) return;

        const copyPeriods = [...newCalendar[currDay].periods];

        copyPeriods[dayIndex] = {} as any;

        newCalendar[currDay] = {
            ...newCalendar[currDay],
            periods: copyPeriods,
        };

        dispatch(
            storeHome({
                ...homeSelect.data,
                result: [
                    { ...homeSelect.data.result[0], calendar: newCalendar },
                ],
            })
        );

        setAnchorEl(null);
    };

    return (
        <div
            className="divider-container"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <div className="teacher-container" ref={drop}>
                <List
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        opacity: isDragging ? "0.7" : "1",
                        paddingTop: 0,
                        paddingBottom: 0,
                        width: "100%",
                    }}
                    ref={drag}
                    className="teacher-draggable"
                    onClick={handlePopoverOpen}
                >
                    {periodStart ? (
                        <ListItemText
                            primary={discipline}
                            primaryTypographyProps={{
                                fontSize: 12,
                                padding: "0 !important",
                            }}
                            sx={{
                                wordBreak: "break-word",
                                marginTop: 0,
                                marginBottom: 0,
                                padding: 0,
                                width: "140px",
                            }}
                            secondary={`${name} - ${period?.start} `}
                        />
                    ) : (
                        <ListItemText
                            primary={period?.start}
                            primaryTypographyProps={{
                                fontSize: 12,
                            }}
                        />
                    )}
                </List>

                {!!teacher && (
                    <Popover
                        id="mouse-over-popover"
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                    >
                        <RemoveButton onClick={removeTeacher}>
                            Remover <Delete />
                        </RemoveButton>
                    </Popover>
                )}
            </div>
        </div>
    );
}

export default DraggableCell;
