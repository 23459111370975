import React from "react";
import { Button, Divider, Drawer, MenuItem, TextField } from "@mui/material";
import validator from "validator";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Edit, Delete } from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Container, Form } from "./calendar.module";
import {
    getCalendarAsync,
    updateCalendarAsync,
    postCalendarAsync,
    deleteCalendarAsync,
    selectCalendar,
} from "./calendarSlice";
import { DrawerContainer, DrawerTitle } from "../teacher/teacher.module";
import { Table } from "../../components";

interface ICalendar {
    id?: number;
    type: string;
    description: string;
    name: string;
    date: Date;
}

export function Calendar() {
    const dispatch = useAppDispatch();
    const { data, status } = useAppSelector(selectCalendar);

    const [show, setShow] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<ICalendar>({
        type: "escolar",
        name: "",
        date: null,
        description: "",
    });
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [errors, setErrors] = React.useState({
        type: "",
        name: "",
        date: null,
    });

    const [search, setSearch] = React.useState("");

    // React.useEffect(() => {
    //     dispatch(getCalendarAsync({ page, rowsPerPage }));
    // }, []);

    React.useEffect(() => {
        dispatch(getCalendarAsync({ page, rowsPerPage }));
    }, [dispatch, page, rowsPerPage]);

    const handleUpdate = React.useCallback(() => {
        dispatch(updateCalendarAsync(values))
            .then(() => {
                dispatch(getCalendarAsync({ page, rowsPerPage }));
            })
            .catch((error) => {});
    }, [dispatch, page, rowsPerPage, values]);

    const handleCreate = React.useCallback(() => {
        dispatch(postCalendarAsync(values))
            .then(() => {
                dispatch(getCalendarAsync({ page, rowsPerPage }));
            })
            .catch((error) => {});
    }, [dispatch, page, rowsPerPage, values]);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();
            if (
                validator.isEmpty(errors.name) &&
                validator.isEmpty(errors.type)
            ) {
                if (!!values?.id) {
                    handleUpdate();
                } else {
                    handleCreate();
                }

                const data = {
                    type: "escolar",
                    name: "",
                    date: null,
                    description: "",
                };
                setShow(false);
                setValues(data);
            }
        },
        [errors.name, errors.type, handleCreate, handleUpdate, values?.id]
    );

    const handleVerify = React.useCallback(
        (data: string, property: string) => {
            if (validator.isEmpty(data)) {
                return setErrors({
                    ...errors,
                    [property]: "Campo obrigatório",
                });
            }
        },
        [errors]
    );

    const handleChange =
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: (event.target as HTMLInputElement).value,
            });
        };

    const handleClearError = (property: string) => {
        setErrors({
            ...errors,
            [property]: "",
        });
    };

    const handleDelete = React.useCallback(
        (id: number) => {
            dispatch(deleteCalendarAsync(id));
        },
        [dispatch]
    );

    const handleSearch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value;
            dispatch(getCalendarAsync({ start: value, end: value }));
            setSearch(value);
        },
        [dispatch]
    );

    return (
        <Container className="period-container">
            <Table
                columns={[
                    {
                        title: "Data",
                        key: "date",
                        align: "left",
                        type: "date",
                    },
                    {
                        title: "Nome",
                        key: "name",
                        align: "left",
                    },
                    {
                        title: "Tipo",
                        key: "type",
                        align: "left",
                    },
                    {
                        title: "Descrição",
                        key: "description",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar data",
                        onClick: (rowData) => {
                            setValues(rowData);
                            return setShow(true);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar data",
                        onClick: (rowData) => {
                            return handleDelete(rowData?.id);
                        },
                    },
                ]}
                data={data.filter(
                    (item) =>
                        item.name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                        item.description
                            ?.toLowerCase()
                            .includes(search.toLowerCase())
                )}
                onClickAdd={() => setShow(true)}
                onClickRefresh={() =>
                    dispatch(getCalendarAsync({ page: 0, rowsPerPage }))
                }
                handleChange={handleSearch}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
            />
            <Drawer
                anchor="right"
                open={show}
                onClose={() => {
                    const data = {
                        type: "escolar",
                        name: "",
                        date: null,
                        description: "",
                    };
                    setValues(data);
                    setErrors(data);
                    setShow(false);
                }}
            >
                <DrawerContainer>
                    <DrawerTitle>Período</DrawerTitle>
                    <Divider
                        sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        variant="inset"
                    />
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        {/* <TextField
                            label="Data"
                            size="small"
                            placeholder="08:40"
                            value={values.date}
                            name="date"
                            onChange={handleChange("date")}
                            onFocus={() => handleClearError("date")}
                            onBlur={() => handleVerify(values.date, "date")}
                            error={!!errors.date}
                            helperText={errors.date}
                            fullWidth
                            autoComplete="off"
                        /> */}
                        <DatePicker
                            label="Data"
                            value={values?.date}
                            onChange={(newValue) => {
                                setValues({ ...values, date: newValue });
                            }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} />
                            )}
                        />
                        <TextField
                            label="Nome"
                            size="small"
                            value={values?.name}
                            name="name"
                            onChange={handleChange("name")}
                            onFocus={() => handleClearError("name")}
                            onBlur={() => handleVerify(values?.name, "name")}
                            error={!!errors?.name}
                            helperText={errors?.name}
                            fullWidth
                            autoComplete="off"
                        />
                        <TextField
                            label="Tipo de data"
                            size="small"
                            value={values?.type}
                            name="type"
                            onChange={handleChange("type")}
                            onFocus={() => handleClearError("type")}
                            onBlur={() => handleVerify(values?.type, "type")}
                            error={!!errors?.date}
                            helperText={errors?.date}
                            fullWidth
                            autoComplete="off"
                            select
                        >
                            <MenuItem value="Escolar">Escolar</MenuItem>
                            <MenuItem value="feriado nacional">
                                Feriado Nacional
                            </MenuItem>
                            <MenuItem value="feriado estadual">
                                Feriado Estadual
                            </MenuItem>
                            <MenuItem value="feriado municipal">
                                Feriado Municipal
                            </MenuItem>
                        </TextField>
                        <TextField
                            name="description"
                            label="Descrição"
                            size="small"
                            onChange={handleChange("description")}
                            value={values?.description}
                            fullWidth
                            rows={4}
                            multiline
                            autoComplete="off"
                        />
                        <Button type="submit" variant="contained">
                            Salvar
                        </Button>
                    </Form>
                </DrawerContainer>
            </Drawer>
        </Container>
    );
}
