import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "../../store/store";
import {
    fetchAvailabilities,
    getAvailabilityByTeacherId,
    updateAvailability,
    deleteAvailability,
    postAvailability,
} from "./availabilityAPI";

interface IAvailability {
    id?: number;
    teacherId?: number;
    end: string;
    start: string;
    dayOfWeek: string;
}

export interface availabilityState {
    data: IAvailability[];
    status: "idle" | "loading" | "failed";
}

const initialState: availabilityState = {
    data: [],
    status: "idle",
};

export const getAvailabilitiesAsync = createAsyncThunk(
    "availability/fetchAvailabilities",
    async (params: object) => {
        const response = (await fetchAvailabilities(params)) as AxiosResponse;

        return response.data;
    }
);

export const getAvailabilitiesByTeacherIdAsync = createAsyncThunk(
    "availability/fetchAvailabilitiesByTeacherId",
    async (teacherId: number) => {
        const response = (await getAvailabilityByTeacherId(
            teacherId
        )) as AxiosResponse;

        return response.data;
    }
);

export const updateAvailabilityAsync = createAsyncThunk(
    "availability/update",
    async (data: IAvailability) => {
        const response = (await updateAvailability(data)) as AxiosResponse;
        return response.data;
    }
);

export const deleteAvailabilityById = createAsyncThunk(
    "availability/deleteById",
    async (id: number) => {
        const response = (await deleteAvailability(id)) as AxiosResponse;
        return response.data;
    }
);

export const createAvailability = createAsyncThunk(
    "availability/create",
    async (data: IAvailability) => {
        const response = (await postAvailability(data)) as AxiosResponse;
        return response.data;
    }
);

export const availabilitySlice = createSlice({
    name: "teacher",
    initialState,
    reducers: {
        addAvailability: (state) => {
            let obj: IAvailability = {
                end: "",
                start: "",
                dayOfWeek: "segunda",
            };

            state.data.push(obj);
        },
        subAvailability: (state, action: PayloadAction<{ index: number }>) => {
            let { index } = action.payload;
            let tempArr = state.data;
            tempArr.splice(index);

            state.data = tempArr;
        },
        clearAvailabilities: (state) => {
            state.data = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAvailabilitiesAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getAvailabilitiesAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.data = action.payload.data;
            })
            .addCase(getAvailabilitiesByTeacherIdAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                getAvailabilitiesByTeacherIdAsync.fulfilled,
                (state, action) => {
                    state.status = "idle";
                    state.data = action.payload.data;
                }
            )
            .addCase(updateAvailabilityAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateAvailabilityAsync.fulfilled, (state, action) => {
                state.status = "idle";
            })
            .addCase(deleteAvailabilityById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteAvailabilityById.fulfilled, (state, action) => {
                state.status = "idle";
            });
    },
});

export const { addAvailability, subAvailability, clearAvailabilities } =
    availabilitySlice.actions;

export const selectAvailability = (state: RootState) => state.availabilities;

export default availabilitySlice.reducer;
