import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "../../store/store";
import {
    updatePeriod,
    postPeriod,
    fetchPeriods,
    deletePeriod,
    fetchPeriodsByShift,
} from "./periodApi";

interface IPeriod {
    id?: number;
    start: string;
    end: string;
}

interface IPeriodResponse {
    data: IPeriod[];
}

export interface periodState {
    data: IPeriod[];
    status: "idle" | "loading" | "failed";
    error: string | null;
}

const initialState: periodState = {
    data: [],
    status: "idle",
    error: null,
};

export const getPeriodsAsync = createAsyncThunk(
    "period/fetchPeriods",
    async (params: object) => {
        const response = (await fetchPeriods(params)) as AxiosResponse;
        return response.data;
    }
);

export const getPeriodsByShiftAsync = createAsyncThunk(
    "period/fetchPeriodsByShift",
    async (shiftId: number) => {
        const response = (await fetchPeriodsByShift(shiftId)) as AxiosResponse;
        return response.data;
    }
);

export const updatePeriodsAsync = createAsyncThunk(
    "period/updatePeriod",
    async (data: IPeriod) => {
        const response = (await updatePeriod(data)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload

        return response.data;
    }
);

export const postPeriodsAsync = createAsyncThunk(
    "period/postPeriod",
    async (data: IPeriod) => {
        const response = (await postPeriod(data)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const deletePeriodsAsync = createAsyncThunk(
    "period/deletePeriod",
    async (id: number) => {
        const response = (await deletePeriod(id)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const periodSlice = createSlice({
    name: "period",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getPeriodsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                getPeriodsAsync.fulfilled,
                (state, action: PayloadAction<IPeriodResponse>) => {
                    state.status = "idle";
                    state.data = action.payload.data;
                }
            )
            .addCase(getPeriodsAsync.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(deletePeriodsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                deletePeriodsAsync.fulfilled,
                (state, action: PayloadAction<{ data: number }>) => {
                    let temp = state.data.filter(
                        (item) => item.id !== action.payload.data
                    );
                    state.data = temp;
                    state.status = "idle";
                }
            )
            .addCase(deletePeriodsAsync.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(postPeriodsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                postPeriodsAsync.fulfilled,
                (state, action: PayloadAction<{ data: IPeriod }>) => {
                    state.status = "idle";
                }
            )
            .addCase(postPeriodsAsync.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(updatePeriodsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updatePeriodsAsync.fulfilled, (state) => {
                state.status = "loading";
            })
            .addCase(updatePeriodsAsync.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(getPeriodsByShiftAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                getPeriodsByShiftAsync.fulfilled,
                (state, action: PayloadAction<{ data: IPeriod[] }>) => {
                    state.status = "loading";
                    state.data = action.payload.data;
                }
            )
            .addCase(getPeriodsByShiftAsync.rejected, (state) => {
                state.status = "failed";
            });
    },
});

export const selectPeriod = (state: RootState) => state.periods;

export default periodSlice.reducer;
