import styled from "styled-components";

export const Container = styled.div`
    h3 {
        font-size: 18px;
        margin: 0;
    }
`;

export const List = styled.div`
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;
