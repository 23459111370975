import styled from "styled-components";

export const Container = styled.div`
    background: white;
    padding: 20px;
    width: 400px;
    padding: 30px;

    h1 {
        color: black;
        margin: 0;
    }

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    p {
        margin: 20px 0;
    }

    .buttons {
        display: flex;
        gap: 20px;

        button {
            width: 100px;
            border: none;
            outline: none;
            padding: 10px;
            color: white;
            font-size: 16px;
            font-weight: 700;
            border-radius: 4px;
            cursor: pointer;
        }

        button:first-of-type {
            background: var(--primary-green);
        }
        button:last-of-type {
            background: var(--alert-primary);
        }
    }
`;
