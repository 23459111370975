import React from "react";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    CenteredWrapper,
    Container,
    IcconButton,
    InputField,
    Row,
    TableTitle,
} from "./styles";
import { Add, Autorenew } from "@mui/icons-material";
import moment from "moment";
import { LoadingWrapper } from "../../features/class/class.module";
import Loading from "react-loading";

interface IColumn {
    title?: string;
    key?: string;
    width?: string;
    type?: "currency" | "number" | "image" | "date";
    align?: "center" | "left" | "right" | "inherit" | "justify";
}

interface IAction {
    icon: React.ReactElement | JSX.Element | string;
    onClick?: (rowData: any) => void;
    tooltip?: React.ReactElement | JSX.Element | string;
}

interface IProps {
    columns?: IColumn[];
    actions?: IAction[];
    disableActions?: boolean;
    data?: any[];
    onClickAdd?: () => void;
    onClickRefresh?: () => void;
    handleChange?: (value: any) => void;
    handlePage: (page: number) => void;
    handleRowsPerPage: (rowsPerPage: number) => void;
    add?: boolean | false;
    status?: "idle" | "loading" | "failed";
    title?: string;
    page: number;
    rowsPerPage: number;
    loading?: boolean;
}

export default function TableComponent(props: IProps) {
    const {
        columns,
        data,
        actions,
        handleChange,
        onClickAdd,
        onClickRefresh,
        status,
        title,
        page,
        handlePage,
        rowsPerPage,
        handleRowsPerPage,
        disableActions,
    } = props;

    let key = 3;

    const handleChangePage = (event: unknown, newPage: number) => {
        handlePage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        handleRowsPerPage(+event.target.value);
        handlePage(0);
    };

    const renderActions = React.useCallback(
        (item: any) => {
            let actionsRow = actions?.map((action, actIndex) => (
                <Tooltip key={actIndex} title={action.tooltip ?? ""}>
                    <IconButton
                        size="small"
                        onClick={() => action.onClick(item)}
                        disabled={disableActions ? disableActions : false}
                    >
                        {action.icon}
                    </IconButton>
                </Tooltip>
            ));
            return (
                <TableCell key="actions" scope="row" align="center">
                    {actionsRow}
                </TableCell>
            );
        },
        [actions, disableActions]
    );

    const renderBody = () => {
        let rows = [] as any[];

        if (data && data.length > 0) {
            data?.forEach((item, index) => {
                let row: JSX.Element[] = [];
                columns?.forEach((column, colIndex) => {
                    if (
                        "type" in column === true &&
                        column.type === "currency"
                    ) {
                        row.push(
                            <TableCell
                                key={index}
                                width={column?.width}
                                size="small"
                                align={column?.align ?? "inherit"}
                                sx={{
                                    height: "50px !important",
                                    paddingLeft: "30px",
                                }}
                            >
                                {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(item[column?.key ?? ""])}
                            </TableCell>
                        );
                        return;
                    }
                    if (column.key === "isActive") {
                        row.push(
                            <TableCell
                                key={index++}
                                width={column.width}
                                size="small"
                                align={column.align ?? "inherit"}
                                sx={{
                                    height: "50px !important",
                                }}
                            >
                                {item[column.key] ? "Ativo" : "Inativo"}
                            </TableCell>
                        );
                        return;
                    }
                    if ("type" in column === true && column.type === "number") {
                        row.push(
                            <TableCell
                                key={index++ + 1}
                                width={column.width}
                                size="small"
                                align={column.align ?? "right"}
                                sx={{
                                    height: "50px !important",
                                }}
                            >
                                {item[column.key ?? ""]}
                            </TableCell>
                        );
                        return;
                    }
                    if ("type" in column === true && column.type === "date") {
                        row.push(
                            <TableCell
                                key={key++ + 2}
                                width={column.width}
                                size="small"
                                // align={column.align ?? "right"}
                                sx={{
                                    height: "50px!important",
                                }}
                            >
                                {moment(item[column.key ?? ""]).format(
                                    "DD/MM/YYYY"
                                )}
                            </TableCell>
                        );
                        return;
                    }

                    row.push(
                        <TableCell
                            key={key++ + 1}
                            width={column.width}
                            size="small"
                            align={column.align ?? "inherit"}
                            sx={{
                                height: "50px!important",
                            }}
                        >
                            {item[column.key ?? ""]}
                        </TableCell>
                    );
                });
                if (actions) {
                    let actCol = renderActions(item);
                    row.push(actCol);
                }

                rows.push(
                    <TableRow
                        key={key++}
                        sx={{
                            height: "50px",
                        }}
                    >
                        {row}
                    </TableRow>
                );
            });

            return rows;
        } else {
            return (
                <CenteredWrapper>
                    <Typography style={{ marginTop: "50px" }}>
                        Nenhum item encontrado.
                    </Typography>
                </CenteredWrapper>
            );
        }
    };

    const renderHeader = React.useCallback(() => {
        let rows: JSX.Element[] = [];
        columns?.forEach((item, index) => {
            rows.push(
                <TableCell
                    key={index}
                    align="left"
                    size="small"
                    style={{ paddingLeft: "30px" }}
                >
                    {item.title ?? ""}
                </TableCell>
            );
        });
        if (actions) {
            rows.push(
                <TableCell key={key++} width={100} align="left" size="small" />
            );
        }

        return rows;
    }, [actions, columns, key]);

    return (
        <Container>
            <Row>
                {title && <TableTitle>{title}</TableTitle>}
                <InputField
                    variant="filled"
                    sx={{
                        backgroundColor: "transparent",
                        m: 1,
                    }}
                    size="small"
                    placeholder="Buscar..."
                    onChange={handleChange}
                />
                <IcconButton onClick={onClickAdd}>
                    <Add />
                </IcconButton>
                <IcconButton onClick={onClickRefresh}>
                    <Autorenew />
                </IcconButton>
            </Row>
            <TableContainer>
                {status === "loading" && (
                    <LoadingWrapper
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Loading
                            type="spinningBubbles"
                            color={"rgb(9, 160, 17)"}
                            width={30}
                            height={30}
                        />
                    </LoadingWrapper>
                )}
                {status === "idle" && (
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>{renderHeader()}</TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                position: "relative",
                                maxHeight: "400px",
                            }}
                        >
                            {status === "idle" && renderBody()}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <TablePagination
                className="pagination"
                rowsPerPageOptions={[10, 25, 100]}
                component="section"
                count={data?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Quantidade por página"
            />
        </Container>
    );
}
