import React from "react";
import {
    Button,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Switch,
    TextField,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import ReactLoading from "react-loading";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Table } from "../../components";
import { handleToast } from "../../components/Toast";
import {
    Container,
    DrawerContainer,
    DrawerTitle,
    Form,
    Label,
    LoadingWrapper,
    Row,
} from "./teacher.module";
import { deleteTeacher, postTeacher, updateTeacher } from "./teacherAPI";
import { getTeachersAsync } from "./teacherSlice";
import {
    getDisciplinesAsync,
} from "../discipline/disciplineSlice";
import { Availability } from "../../components/TeacherAvailability";
import { UseAvailability } from "../../hooks/useAvailability";


interface IProps { }

interface IAvailability {
    id?: number;
    teacherId?: number;
    end: string;
    start: string;
    dayOfWeek: string;
}

interface ITeacher {
    id?: number;
    name: string;
    discipline?: {
        discipline: string;
        disciplineId: number;
        workload: number;
    };
    disciplineId?: number;
    disciplineid?: number;
    TeacherAvailabilities?: IAvailability[];
    availabilities?: IAvailability[];
    isActive: true | false;
    workload: number;
}

interface IDiscipline {
    id: number;
    workload: number | null;
    isActive: boolean;
    discipline?: string;
    name?: string;
    classes: Array<any>;
    shift: {
        shiftId: number;
        shift: string;
    };
}

const Loading = ReactLoading as any;

export function Teacher(props: IProps) {
    const dispatch = useAppDispatch();

    const { resetAvailabilities } = UseAvailability();

    const { data, status } = useAppSelector(state => state.teachers);
    const disciplineSelector:any = useAppSelector(state => state.disciplines);

    const [show, setShow] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<ITeacher>({
        name: "",
        discipline: undefined,
        TeacherAvailabilities: [],
        disciplineId: null,
        disciplineid: null,
        isActive: true,
        workload: null,
    });
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

    React.useEffect(() => {
        Promise.all([
            dispatch(getTeachersAsync({ page, rowsPerPage })),
            dispatch(getDisciplinesAsync({ page: -1 })),
        ]);
    }, [dispatch, page, rowsPerPage]);

    React.useEffect(() => {
        if (!show) {
            dispatch(getTeachersAsync({ page, rowsPerPage }));
        }
    }, [dispatch, page, rowsPerPage, show]);

    const handleChange =
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: event.target.value,
            });
        };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            isActive: event.target.checked,
        });
    };

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (
            values?.name === null ||
            values?.name === undefined ||
            values?.name.length < 1
        )
            return handleToast(true, "Entre com um nome do Professor.");

        // if (values.workload === null || values.workload === undefined || values.workload < 0)
        //     return handleToast(true, 'Entre com um carga horária válida.')

        if (!!values?.id) {
            return updateTeacher(values as any)
                .then(() => {
                    handleToast(false, "Professor atualizada com sucesso.");
                    setValues({
                        id: undefined,
                        name: "",
                        TeacherAvailabilities: [],
                        isActive: true,
                        workload: null,
                        discipline: undefined,
                        disciplineId: null
                    });
                    setShow(false);
                    return dispatch(getTeachersAsync({ page }));
                })
                .catch((error) => {
                    if (Array.isArray(error)) return handleToast(true, error);

                    return handleToast(true, error);
                });
        }

        return postTeacher(values as any)
            .then(() => {
                handleToast(false, "Professor cadastrado com sucesso.");
                setValues({
                    id: undefined,
                    name: "",
                    TeacherAvailabilities: [],
                    discipline: undefined,
                    isActive: true,
                    workload: null,
                });
                setShow(false);
                return dispatch(getTeachersAsync({ page }));
            })
            .catch((error) => {
                handleToast(true, error.message);
                return;
            });
    };

    const handleDelete = (id: number) => {
        deleteTeacher(id)
            .then(() => {
                handleToast(false, "Professor deletado com sucesso.");
                return dispatch(getTeachersAsync({ page }));
            })
            .catch((error) => {
                handleToast(true, error.message);
                return;
            });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        dispatch(
            getTeachersAsync({
                name: value,
            })
        );
    };

    const renderDisciplines = (data: IDiscipline[]) => {
        let rows = [];

        if (data) {
            data?.forEach((item, index) => {
                rows.push(
                    <MenuItem key={index} value={item?.id}>
                        {item?.name}
                    </MenuItem>
                );
            });
        }

        if (rows.length < 1) {
            rows.push(
                <MenuItem key={"none"} disabled>
                    Nenhuma disciplina cadastrada.
                </MenuItem>
            );
        }

        return rows;
    };

    const returnWorload = (id: number) => {
        if (id) {
            return disciplineSelector?.data?.find((item) => item?.id === id).workload;
        }
        else return "";
    };

    const handleAddAvailability = React.useCallback(() => {
        let availabilities = [...values.TeacherAvailabilities];
        let obj: IAvailability = {
            end: "",
            start: "",
            dayOfWeek: "segunda",
        };
        availabilities.push(obj);
        setValues({
            ...values,
            TeacherAvailabilities: availabilities,
        });
    }, [values]);

    const handleDeleteAvailabilityByIndex = React.useCallback(
        (index) => {
            const newArray = [...values?.TeacherAvailabilities];

            newArray.splice(index, 1);

            setValues({
                ...values,
                TeacherAvailabilities: newArray,
            });
        },
        [values]
    );

    return (
        <Container className="teacher-container">
            <Table
                columns={[
                    {
                        title: "Professor",
                        key: "name",
                        align: "left",
                    },
                    {
                        title: "Disciplina",
                        key: "discipline",
                        align: "left",
                    },
                    {
                        title: "Carga Horária",
                        key: "workload",
                        align: "left",
                        type: "number",
                    },
                    {
                        title: "Status",
                        key: "isActive",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar professor",
                        onClick: (rowData) => {
                            setValues(rowData);
                            return setShow(true);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar professor",
                        onClick: (rowData) => {
                            return handleDelete(rowData?.id);
                        },
                    },
                ]}
                data={data}
                onClickAdd={() => setShow(true)}
                onClickRefresh={() => dispatch(getTeachersAsync({ page }))}
                handleChange={handleSearch}
                status={status}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                title="Professores"
            />
            <Drawer
                anchor="right"
                open={show}
                onClose={() => {
                    setValues({
                        id: null,
                        name: "",
                        TeacherAvailabilities: [],
                        isActive: true,
                        workload: null,
                        discipline: null,
                    });
                    setShow(false);
                    resetAvailabilities();
                }}
            >
                <DrawerContainer>
                    <DrawerTitle>Professor</DrawerTitle>
                    <Divider
                        sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        variant="inset"
                    />
                    <Form onSubmit={handleSubmit}>
                        <TextField
                            label="Professor"
                            size="small"
                            value={values?.name}
                            onChange={handleChange("name")}
                            fullWidth
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            label="Disciplina"
                            size="small"
                            defaultValue={values?.disciplineid}
                            value={values?.disciplineId}
                            onChange={handleChange("disciplineId")}
                            fullWidth
                            select
                        >
                            {disciplineSelector?.status === "loading" && (
                                <LoadingWrapper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Loading
                                        type="spinningBubbles"
                                        color={"rgb(9, 160, 17)"}
                                        width={30}
                                        height={30}
                                    />
                                </LoadingWrapper>
                            )}
                            {disciplineSelector?.status === "idle" &&
                                renderDisciplines(disciplineSelector?.data)}
                        </TextField>
                        <TextField
                            label="Carga Horária"
                            size="small"
                            defaultValue={returnWorload(values?.disciplineid)}
                            value={values?.disciplineId ? returnWorload(values?.disciplineId) : returnWorload(values?.disciplineid)}
                            onChange={handleChange("workload")}
                            fullWidth
                            disabled
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={values?.isActive}
                                    onChange={handleChangeSwitch}
                                    inputProps={{ "aria-label": "isActive" }}
                                />
                            }
                            label="Ativo"
                        />
                        <Button fullWidth type="submit" variant="contained">
                            Salvar
                        </Button>
                    </Form>
                    <FormControl
                        sx={{
                            display: "flex",
                            width: "100%",
                            gap: 0.75,
                        }}
                    >
                        <Row>
                            <Label>Disponibilidade</Label>
                            <IconButton onClick={handleAddAvailability}>
                                <Add />
                            </IconButton>
                        </Row>
                        {values?.TeacherAvailabilities.map((item, index) => (
                            <Availability
                                item={item}
                                index={index}
                                key={item?.id}
                                teacherId={values?.id}
                                deleteByIndex={handleDeleteAvailabilityByIndex}
                            />
                        ))}
                    </FormControl>
                </DrawerContainer>
            </Drawer>
        </Container>
    );
}
