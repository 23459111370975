import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "../../store/store";
import {
    deleteSegment,
    fetchSegments,
    postSegment,
    updateSegment,
} from "./segmentApi";

interface ISegment {
    id?: number;
    name: string;
    isActive: boolean;
}

interface ISegmentResponse {
    data: ISegment[];
}

export interface segmentState {
    data: ISegment[];
    status: "idle" | "loading" | "failed";
    error: string | null;
}

const initialState: segmentState = {
    data: [],
    status: "idle",
    error: null,
};

export const getSegmentsAsync = createAsyncThunk(
    "segment/fetchSegments",
    async (params: object) => {
        const response = (await fetchSegments(params)) as AxiosResponse;

        return response.data;
    }
);

export const updateSegmentsAsync = createAsyncThunk(
    "segment/updateSegment",
    async (data: ISegment) => {
        const response = (await updateSegment(data)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const postSegmentsAsync = createAsyncThunk(
    "segment/postSegment",
    async (data: ISegment) => {
        const response = (await postSegment(data)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const deleteSegmentsAsync = createAsyncThunk(
    "segment/deleteSegment",
    async (id: number) => {
        const response = (await deleteSegment(id)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const segmentSlice = createSlice({
    name: "segment",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getSegmentsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                getSegmentsAsync.fulfilled,
                (state, action: PayloadAction<ISegmentResponse>) => {
                    state.status = "idle";
                    state.data = action.payload.data;
                }
            )
            .addCase(getSegmentsAsync.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(deleteSegmentsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                deleteSegmentsAsync.fulfilled,
                (state, action: PayloadAction<{ data: number }>) => {
                    let temp = state.data.filter(
                        (item) => item.id !== action.payload.data
                    );
                    state.data = temp;
                    state.status = "idle";
                }
            )
            .addCase(deleteSegmentsAsync.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(postSegmentsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                postSegmentsAsync.fulfilled,
                (state, action: PayloadAction<{ data: ISegment }>) => {
                    state.status = "idle";
                }
            )
            .addCase(postSegmentsAsync.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(updateSegmentsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateSegmentsAsync.fulfilled, (state) => {
                state.status = "loading";
            })
            .addCase(updateSegmentsAsync.rejected, (state) => {
                state.status = "failed";
            });
    },
});

export const selectSegment = (state: RootState) => state.segments;
export default segmentSlice.reducer;
