import styled from "styled-components";
import { Typography } from "@mui/material";

export const Container = styled.section`
    display: block;
    width: 100%;
    padding: 20px;

    @media print {
        * {
            margin: 0 !important;
            padding: 0 !important;
        }

        // html,
        // body {
        //     height: initial !important;
        //     background: #fff;
        //     overflow: initial !important;
        //     -webkit-print-color-adjust: exact;
        // }

        #controls,
        .footer,
        .footerarea {
            display: none;
        }
        
        // span,
        // p, 
        // .teacher {
        //     font-size: 8.5pt !important;
        // }

        .teacher-draggable {
            page-break-inside: avoid;
        }

        .teacher-draggable p, .teacher-draggable span{
            font-size: 6pt !important;
        }

        .calendar-header {
            font-size: 8.5pt !important;
        }

        .week-draggable p {
            font-size: 8.5pt !important;
            max-heigth: 2px;
        }

        .calendar-day {
            padding: 0 4px !important;
        }

        .calendar-day div {
            max-height: 45px !important;
            font-size: 7.5pt !important;
        }
        
        @page {
            size: A4;
            margin: 10px 20px;
            page-break-inside: avoid;
        }

        button {
            display: none !important;
        }
    }
`;

export const Content = styled.section`
    position: relative;
    display: block;
    width: 100%;
    border-color: var(--border);
`;

export const Header = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
    gap: 10px;
    padding: 1.5em 0;
    // border-bottom: 1px solid var(--border);
`;

export const IconContainer = styled.aside``;

export const TitleContainer = styled.aside`
    flex: 1;
`;

export const Title = styled(Typography)`
    font-size: 18px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
`;

export const Day = styled(Typography)`
    text-transform: uppercase;
    font-weight: 400;
    // padding: .75em 0;
`;

export const DaysWrapper = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
    // gap: 10px;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border);
`;

export const DayWrapper = styled.section`
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    max-width: 100%;
    padding: 0.75em 0;
    text-align: center;
`;

export const Cell = styled.section<{ holiday: boolean, list: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
    min-height: 3.5rem;
    padding: 5px;
    gap: 0.4rem;
    border-right: 1px solid var(--border);
    overflow: hidden;
    cursor: pointer;
    transition: 0.25s ease-out;
    background: ${({ holiday }) =>
        holiday ? "rgba(255, 0, 0, 0.2)" : "#fff"};

    :hover {
        background: ${({ holiday }) =>
        holiday ? "rgba(255, 0, 0, 0.3)" : "var(--bg-color)"};
        transition: 0.5s ease-out;
    }

    &:last-child {
        border-right: none;
    }

    &.selected {
    }

    &.centralized {
        align-items: center;
        justify-content: center;
    }

    span {
        display: inline-block;
        padding: 5px 0px;
    }

    .teacher-container {
        display: flex;
        position: relative;

        span.teacher {
            padding: 5px 10px;
            border-radius: 4px;
        }

        button {
            background: none;
            color: red;
            font-weight: 700;
            border: none;
            outline: none;
            font-size: 18px;
            cursor: pointer;
            position: absolute;
            right: 10px;
        }
    }

    #${({ list }) => list} {
        opacity: 0;
    }
`;

export const Row = styled.section`
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid var(--border);

    &:last-child {
        border-bottom: none;
    }

    &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export const Body = styled.section``;

export const DateNumberContainer = styled.span`
    margin-bottom: 5px;
    .day {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const RemoveButton = styled.button`
    background: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 8px;
    outline: none;
    border: none;
    padding: 5px 30px;
    background: rgba(0, 0, 0, 0.08);
    cursor: pointer;
`;


export const InputColor = styled.input`
    border: 2px solid #1ea418;
    height: 2rem;
    background:none;
`
export const RowDrawer = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    gap: 5px
`