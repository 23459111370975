import { Delete, Edit, Check, Close } from "@mui/icons-material";
import { IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import React from "react";
import { UseAvailability } from "../../hooks/useAvailability";
import { Row } from "./styles";

interface IAvailability {
    availabilityId?: number;
    teacherId?: number;
    end: string;
    start: string;
    dayOfWeek: string;
}

interface IAvailabilityProps {
    item: IAvailability;
    index: number;
    teacherId: number;
    deleteByIndex: (index: number) => void;
}

export function Availability(props: IAvailabilityProps) {
    const {
        handleUpdateAvailability,
        handleDeleteAvailability,
        handleCreateAvailability,
    } = UseAvailability();

    const { item, index, teacherId } = props;

    const [values, setValues] = React.useState<IAvailability>({
        dayOfWeek: "segunda",
        end: "",
        start: "",
        teacherId,
    });

    const [edit, setEdit] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (item) {
            setValues(item);
        }
    }, [item]);

    const handleChangeDayOfWeek = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            return setValues({
                ...values,
                dayOfWeek: (event.target as HTMLInputElement).value,
            });
        },
        [values]
    );

    const handleChangeStart = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            return setValues({
                ...values,
                start: (event.target as HTMLInputElement).value,
            });
        },
        [values]
    );

    const handleChangeEnd = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            return setValues({
                ...values,
                end: (event.target as HTMLInputElement).value,
            });
        },
        [values]
    );

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();

            let data = values;
            const obj = { ...data, teacherId };

            if (values.availabilityId) await handleUpdateAvailability(obj);
            else await handleCreateAvailability(obj);

            setEdit(false);

            return;
        },
        [handleCreateAvailability, handleUpdateAvailability, teacherId, values]
    );

    const handleDelete = React.useCallback(
        (id: number) => {
            if (id) {
                let data = values;
                data.teacherId = teacherId;
                handleDeleteAvailability({ id, index });

                if (values.availabilityId)
                    return handleUpdateAvailability(data);
            }

            return props.deleteByIndex(index);
        },
        [props, index, values, teacherId, handleDeleteAvailability, handleUpdateAvailability]
    );

    return (
        <form onSubmit={handleSubmit}>
            <Row key={index}>
                {!edit && (
                    <Tooltip title="Deletar disponibilidade">
                        <IconButton
                            size="small"
                            onClick={() => handleDelete(values.availabilityId)}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                )}
                {edit && (
                    <Tooltip title="Cancelar">
                        <IconButton
                            size="small"
                            onClick={() => {
                                setValues(item);
                                setEdit(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                )}
                {!edit && (
                    <Tooltip title="Editar disponibilidade">
                        <IconButton size="small" onClick={() => setEdit(true)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
                {edit && (
                    <Tooltip title="Salvar disponibilidade">
                        <IconButton size="small" type="submit">
                            <Check />
                        </IconButton>
                    </Tooltip>
                )}
                <TextField
                    select
                    size="small"
                    value={values.dayOfWeek}
                    onChange={handleChangeDayOfWeek}
                    sx={{
                        width: "120px",
                    }}
                    disabled={!edit}
                >
                    <MenuItem value="segunda">segunda</MenuItem>
                    <MenuItem value="terça">terça</MenuItem>
                    <MenuItem value="quarta">quarta</MenuItem>
                    <MenuItem value="quinta">quinta</MenuItem>
                    <MenuItem value="sexta">sexta</MenuItem>
                    <MenuItem value="sábado">sabado</MenuItem>
                    <MenuItem value="domingo">domingo</MenuItem>
                </TextField>
                <TextField
                    placeholder="08:00"
                    value={values.start}
                    size="small"
                    onChange={handleChangeStart}
                    sx={{
                        width: "80px",
                    }}
                    disabled={!edit}
                />
                <TextField
                    placeholder="08:00"
                    value={values.end}
                    size="small"
                    onChange={handleChangeEnd}
                    sx={{
                        width: "80px",
                    }}
                    disabled={!edit}
                />
            </Row>
        </form>
    );
}
