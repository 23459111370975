import Api from "../../services/api";

interface ILoginData {
    username: string;
    password: string;
}

export const authenticateUser = async (loginData: ILoginData) => {
    const { data } = await Api.post("auth", { data: loginData });
    return data;
};

export const createUser = async (loginData: ILoginData) => {
    const { data } = await Api.post("users", { data: loginData });
    return data;
};

export const getUser = async () => {
    const { data } = await Api.get("users");
    return data;
};
