import React from "react";
import {
    Button,
    Divider,
    Drawer,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { isEmpty } from "validator";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Table } from "../../components";
import { handleToast } from "../../components/Toast";
import {
    Container,
    DrawerContainer,
    DrawerTitle,
    Form,
    LoadingWrapper,
} from "./class.module";
import { deleteClass, postClass, updateClass } from "./classAPI";
import { getClassesAsync } from "./classSlice";
import { getShiftsAsync, selectShift } from "../shift/shiftSlice";
import Loading from "react-loading";
import { getSegmentsAsync, selectSegment } from "../segment/segmentSlice";

interface IProps { }

// interface IClass {
//     id?: number;
//     name?: string;
//     shiftName: string;
//     segmentName: string;
//     isActive: true | false;
//     shiftId: string;
//     segmentId: string;
// }

export function Class(props: IProps) {
    const dispatch = useAppDispatch();

    const { data, status, loading } = useAppSelector(state => state.class);

    const [show, setShow] = React.useState(false);
    const [values, setValues] = React.useState<any>({
        name: "",
        shiftName: "",
        segmentName: "",
        isActive: true,
        segmentId: "",
        shiftId: "",
    });

    const shifts = useAppSelector(selectShift);
    const segments = useAppSelector(selectSegment);

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

    React.useEffect(() => {
        Promise.all([
            dispatch(getClassesAsync({ page, rowsPerPage })),
            dispatch(getShiftsAsync({ page: -1 })),
            dispatch(getSegmentsAsync({ page: -1 })),
        ]);
    }, [dispatch, page, rowsPerPage]);

    const handleChange =
        (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
                ...values,
                [property]: event.target.value,
            });
        };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            isActive: event.target.checked,
        });
    };

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (isEmpty(values.name))
            return handleToast(true, "Entre com um nome da disciplina.");

        if (!values.shiftId)
            return handleToast(true, "Entre com um período válida.");

        if (!values.segmentId)
            return handleToast(true, "Entre com um segmento válida.");

        let data = {
            ...values
        };

        if (values.id) {
            return updateClass(data)
                .then(() => {
                    handleToast(false, "Disciplina atualizada com sucesso.");
                    setValues({
                        id: undefined,
                        name: "",
                        segmentName: "",
                        shiftName: "",
                        segmentId: "",
                        shiftId: "",
                        isActive: true,
                    });
                    setShow(false);
                    return dispatch(getClassesAsync({ page, rowsPerPage }));
                })
                .catch((error) => {
                    if (Array.isArray(error)) return handleToast(true, error);

                    return handleToast(true, error);
                });
        }

        return postClass(data)
            .then(() => {
                handleToast(false, "Disciplina cadastrada com sucesso.");
                setValues({
                    name: "",
                    segmentName: "",
                    shiftName: "",
                    segmentId: "",
                    shiftId: "",
                    isActive: true,
                });
                setShow(false);
                return dispatch(getClassesAsync({ page, rowsPerPage }));
            })
            .catch((error) => {
                handleToast(true, error.message);
                return;
            });
    };

    const handleDelete = (id: number) => {
        deleteClass(
            {
                id: id,
                isActive: false
            })
            .then(() => {
                handleToast(false, "Disciplina deletada com sucesso.");
                return dispatch(getClassesAsync({ page }));
            })
            .catch((error) => {
                handleToast(true, error.message);
                return;
            });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            getClassesAsync({
                name: event.target.value,
            })
        );
    };

    const renderShifts = (data: any) => {
        let rows = [];

        if (data) {
            data.map((item, index) => {
                return rows.push(
                    <MenuItem key={index} value={item.id}>
                        {item.name}
                    </MenuItem>
                );
            });
        }

        if (rows.length < 1) {
            rows.push(
                <MenuItem key={"none"} disabled>
                    Nenhum turno cadastrado.
                </MenuItem>
            );
        }

        return rows;
    };

    const renderSegments = (data: any) => {
        let rows = [];

        if (data) {
            data.map((item, index) => {
                return rows.push(
                    <MenuItem key={index} value={item.id}>
                        {item.name}
                    </MenuItem>
                );
            });
        }

        if (rows.length < 1) {
            rows.push(
                <MenuItem key={"none"} disabled>
                    Nenhum segmento cadastrado.
                </MenuItem>
            );
        }

        return rows;
    };

    return (
        <Container className="daschboard-container">
            <Table
                columns={[
                    {
                        title: "Turma",
                        key: "name",
                        align: "left",
                    },
                    {
                        title: "Segmento",
                        key: "segmentName",
                        align: "left",
                    },
                    {
                        title: "Turno",
                        key: "shiftName",
                        align: "left",
                    },
                    {
                        title: "Status",
                        key: "isActive",
                        align: "left",
                    },
                ]}
                actions={[
                    {
                        icon: <Edit />,
                        tooltip: "Editar turma",
                        onClick: (rowData) => {
                            setValues(rowData);
                            return setShow(true);
                        },
                    },
                    {
                        icon: <Delete />,
                        tooltip: "Deletar turma",
                        onClick: (rowData) => {
                            return handleDelete(rowData.id);
                        },
                    },
                ]}
                data={data}
                onClickAdd={() => setShow(true)}
                onClickRefresh={() =>
                    dispatch(getClassesAsync({ page, rowsPerPage }))
                }
                handleChange={handleSearch}
                status={status}
                loading={loading}
                page={page}
                rowsPerPage={rowsPerPage}
                handlePage={(page) => setPage(page)}
                handleRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                title="Turmas"
            />
            <Drawer anchor="right" open={show} onClose={() => setShow(false)}>
                <DrawerContainer>
                    <DrawerTitle>Turma</DrawerTitle>
                    <Divider
                        sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                        variant="inset"
                    />
                    <Form onSubmit={handleSubmit}>
                        <TextField
                            label="Nome"
                            size="small"
                            value={values?.name}
                            onChange={handleChange("name")}
                            fullWidth
                        />
                        <TextField
                            label="Turno"
                            size="small"
                            value={values?.shiftId}
                            onChange={handleChange("shiftId")}
                            fullWidth
                            select
                        >
                            {shifts?.status === "loading" && (
                                <LoadingWrapper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Loading
                                        type="spinningBubbles"
                                        color={"rgb(9, 160, 17)"}
                                        width={30}
                                        height={30}
                                    />
                                </LoadingWrapper>
                            )}
                            {shifts?.status === "idle" &&
                                renderShifts(shifts?.data)}
                        </TextField>
                        <TextField
                            label="Segmento"
                            size="small"
                            value={values?.segmentId}
                            onChange={handleChange("segmentId")}
                            fullWidth
                            select
                        >
                            {segments?.status === "loading" && (
                                <LoadingWrapper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Loading
                                        type="spinningBubbles"
                                        color={"rgb(9, 160, 17)"}
                                        width={30}
                                        height={30}
                                    />
                                </LoadingWrapper>
                            )}
                            {segments?.status === "idle" &&
                                renderSegments(segments?.data)}
                        </TextField>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={values?.isActive}
                                    onChange={handleChangeSwitch}
                                    inputProps={{ "aria-label": "isActive" }}
                                />
                            }
                            label="Ativo"
                        />
                        <Button fullWidth type="submit" variant="contained">
                            Salvar
                        </Button>
                    </Form>
                </DrawerContainer>
            </Drawer>
        </Container>
    );
}
