import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

    :root{
        --primary-blue: #2020e6;
        --primary-white: #FFF;
        --primary-gray: #1c1c1c;
        --primary-green: #1ea418;

        --alert-primary: #FF0000;
        --alert-secundary: #ff8400;
        --alert-tertiary: #fbff00;

        --border: rgba(0,0,0,.2);
        --bg-color: rgba(0,0,0,0.1);

        --text-body:  400 16px 'Roboto', sans-serif;
        --text-medium: 500 20px 'Roboto', sans-serif;
        --text-title:  700 24px 'Roboto', sans-serif;
    }

    * {
        box-sizing: border-box;
    }

    body {
        background-color: var(--bg-color);
    }
            

    body, 
    html {
        font-family: "Roboto", sans-serif;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #root {}
`;
