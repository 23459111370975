import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "../../store/store";
import { deleteShift, fetchShifts, postShift, updateShift } from "./shiftApi";

interface IShift {
    id?: number;
    name: string;
}

interface IShiftResponse {
    data: IShift[];
}

export interface shiftState {
    data: IShift[];
    status: "idle" | "loading" | "failed";
    error: string | null;
}

const initialState: shiftState = {
    data: [],
    status: "idle",
    error: null,
};

export const getShiftsAsync = createAsyncThunk(
    "shift/fetchShifts",
    async (params: object) => {
        const response = (await fetchShifts(params)) as AxiosResponse;

        return response.data;
    }
);

export const updateShiftsAsync = createAsyncThunk(
    "shift/updateShift",
    async (data: IShift) => {
        const response = (await updateShift(data)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const postShiftsAsync = createAsyncThunk(
    "shift/postShift",
    async (data: IShift) => {
        const response = (await postShift(data)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const deleteShiftsAsync = createAsyncThunk(
    "shift/deleteShift",
    async (id: number) => {
        const response = (await deleteShift(id)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const shiftSlice = createSlice({
    name: "shift",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getShiftsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                getShiftsAsync.fulfilled,
                (state, action: PayloadAction<IShiftResponse>) => {
                    state.status = "idle";
                    state.data = action.payload.data;
                }
            )
            .addCase(getShiftsAsync.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(deleteShiftsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                deleteShiftsAsync.fulfilled,
                (state, action: PayloadAction<{ data: number }>) => {
                    let temp = state.data.filter(
                        (item) => item.id !== action.payload.data
                    );
                    state.data = temp;
                    state.status = "idle";
                }
            )
            .addCase(deleteShiftsAsync.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(postShiftsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(
                postShiftsAsync.fulfilled,
                (state, action: PayloadAction<{ data: IShift }>) => {
                    state.status = "idle";
                }
            )
            .addCase(postShiftsAsync.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(updateShiftsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateShiftsAsync.fulfilled, (state) => {
                state.status = "loading";
            })
            .addCase(updateShiftsAsync.rejected, (state) => {
                state.status = "failed";
            });
    },
});

export const selectShift = (state: RootState) => state.shifts;

export default shiftSlice.reducer;
