import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUser } from "./loginApi";
import { RootState } from "../../store/store";

export interface UserState {
    data: any | null;
    loading: boolean;
}

const initialState: UserState = {
    data: null,
    loading: false,
};

export const userFetch = createAsyncThunk("user/saveUser", async () => {
    const response = await getUser();

    return response.data;
});

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUser: (state, action: PayloadAction<any>) => {
            state.data = { ...action.payload };
        },
        clearUser: (state) => {
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userFetch.pending, (state) => {
                state.loading = true;
            })
            .addCase(userFetch.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(userFetch.rejected, (state) => {
                state.loading = false;
                state.data = null;
            });
    },
});

export const { saveUser, clearUser } = userSlice.actions;

export const userSelector = (state: RootState) => state.user.data;
export const userLoadingSelector = (state: RootState) => state.user.loading;

export default userSlice.reducer;
