import { AxiosResponse } from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { getClassSchedules } from "./homeAPI";

interface IDiscipline {
    id: number;
    name: string;
    workload: number;
    isActive: boolean;
}

interface ITeacher {
    id: number;
    name: string;
    isActive: boolean;
    Discipline: IDiscipline;
    TeacherAvailabilities?: IAvailability[];
}

interface IAvailability {
    id: number;
    dayOfWeek: string;
    start: string;
    end: string;
    Teacher: ITeacher;
    TeacherAvailabilities?: IAvailability[];
}

interface IPeriods {
    teacher: string;
    teacherId: number;
    discipline: string;
    disciplineId: number;
    start: string;
    end: string;
}

interface ICalendar {
    day: number;
    name: string;
    periods: IPeriods[];
}

interface IResponseHomeState {
    calendar: ICalendar[];
    month: string;
}

export interface IHomeState {
    data: {
        result: IResponseHomeState[];
        disciplineHours: [];
        classId?: number;
        class?: string;
        segment?: string;
        segmentId?: number;
        shift?: string;
        shiftId?: number;
    };
    status: "idle" | "loading" | "failed";
    error: any;
}

const initialState: IHomeState = {
    data: null,
    status: "idle",
    error: null,
};

export const getClassSchedulesAsync = createAsyncThunk(
    "home/getClassSchedulesAsync",
    async ({ params, ...rest }: any) => {
        const response = (await getClassSchedules(params)) as AxiosResponse;
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const homeSlice = createSlice({
    name: "home",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        storeHome: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getClassSchedulesAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getClassSchedulesAsync.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "failed";
            })
            .addCase(getClassSchedulesAsync.fulfilled, (state, action) => {
                state.status = "idle";
                state.data = action.payload.data;
            });
    },
});

export const { storeHome } = homeSlice.actions;

export const selectHome = (state: RootState) => state.home;

export default homeSlice.reducer;
