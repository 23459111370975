import Api from "../../services/api";
import { IParams } from "./teacherList.Slice";

export function getTeacherList(classId: number, params: IParams) {
    return new Promise((resolve, reject) => {
        Api.get(`/teacher-list/${classId}`, { params })
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
            });
    });
}
