import styled from "styled-components";

export const Container = styled.div`
    margin: 20px 0;
    width: 100%;

    h6 {
        font-size: 1.25rem;
    }

    > div {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
`;

export const Card = styled.div`
    width: 100%;
    max-width: 180px;
    padding: 20px;
    border: 1px solid #d3d3d3;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
        font-size: 12px;
        font-weight: 600;
        margin: 0;
        color: #7d7d7d;
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 10px;
        margin-bottom: -15px;
        width: 100%;
    }

    .times {
        display: flex;
        align-items: center;
        flex-direction: column;
        span {
            color: #7d7d7d;
            font-size: 12px;

            gap: 3px;
            display: flex;
            align-items: center;

            ::before {
                content: "";
                height: 12px;
                width: 12px;
                display: block;
                border-radius: 5px;
            }

            &:first-child::before {
                background: var(--primary-green);
            }

            &:last-child::before {
                background: #f0e5fc;
            }

            b {
                margin-left: 0px;
            }
        }

        display: flex;
        gap: 15px;

        margin-top: auto;
    }
`;
