import Api from "../../services/api";

interface IPeriod {
    id?: number;
    start: string;
    end: string;
}

interface IPostPeriodRequest {
    data: IPeriod;
}

export function fetchPeriods(params: object = null) {
    return new Promise((resolve, reject) => {
        Api.get("/class-periods", { params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

/**
 * 
 * @param shiftId 
 * @returns Retorna listagem com período dos horários de aulas.
 */
export function fetchPeriodsByShift(shiftId) {
    return new Promise((resolve, reject) => {
        Api.get(`/class-periods/shift/${shiftId}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function postPeriod(data: IPeriod) {
    return new Promise<IPostPeriodRequest>((resolve, reject) => {
        Api.post("/class-periods", { data })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function updatePeriod(data: IPeriod) {
    return new Promise((resolve, reject) => {
        Api.put(`/class-periods/${data.id}`, { data })
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

export function deletePeriod(id: number) {
    return new Promise((resolve, reject) => {
        Api.delete(`/class-periods/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
