import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authenticateUser } from "./loginApi";
import { RootState } from "../../store/store";

export interface TokenState {
    data: { token: string } | null;
    loading: boolean;
}

const initialState: TokenState = {
    data: null,
    loading: false,
};

interface ISignin {
    username: string;
    password: string;
}

export const loginToken = createAsyncThunk(
    "token/saveToken",
    async (data: ISignin) => {
        const response = await authenticateUser(data);
        return response;
    }
);

export const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        saveToken: (state, action: PayloadAction<{ token: string }>) => {
            state.data = action.payload;
        },
        clearToken: (state) => {
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginToken.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginToken.rejected, (state) => {
                state.loading = false;
            })
            .addCase(loginToken.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            });
    },
});

export const { saveToken, clearToken } = tokenSlice.actions;

export const tokenSelector = (state: RootState) => state.token.data;

export default tokenSlice.reducer;
