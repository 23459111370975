import { useMemo } from "react";
import { Container, Card } from "./styles";
import Chart from "react-apexcharts";

interface IProps {
    disciplineHours: any[];
    month: string;
}

const DisciplineHours: React.FC<IProps> = (props) => {
    const options = {
        plotOptions: {
            radialBar: {
                startAngle: -150,
                endAngle: 150,
                track: {
                    background: "#f0e5fc",
                    strokeWidth: "100%",
                    margin: 5,
                },
                hollow: {
                    size: "65%",
                },

                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        color: "#000000",
                        fontSize: "20px",
                        fontWeight: "700",
                        offsetY: 5,
                    },
                },
            },
        },
        fill: {
            colors: ["#1ea418"],
        },

        stroke: {
            lineCap: "round" as "round",
        },
    };

    const formattedHours = useMemo(() => {
        return props.disciplineHours.map((item) => {
            if (item[props.month]) {
                return {
                    discipline: item.discipline,
                    hours: item[props.month],
                    total: item.total,
                };
            }
            return {};
        });
    }, [props]);

    return (
        <Container>
            <h6>CARGA HORÁRIA</h6>
            <div>
                {formattedHours.map((item) => {
                    if (!item?.discipline || !item?.hours || !item?.total)
                        return null;
                    return (
                        <Card>
                            <p>{item?.discipline}</p>
                            <Chart
                                options={options}
                                type="radialBar"
                                series={[
                                    Number(
                                        (
                                            (item.hours * 100) /
                                            item.total
                                        ).toFixed(0)
                                    ),
                                ]}
                                height={200}
                            />
                            <div className="times">
                                <span>
                                    Utilizado: <b>{item.hours}h</b>
                                </span>
                                <span>
                                    Total: <b>{item.total}h</b>
                                </span>
                            </div>
                        </Card>
                    );
                })}
            </div>
        </Container>
    );
};

export default DisciplineHours;
